import {useEffect} from 'react';

import {useInterval} from 'hooks';
import {isDemo} from 'lib/env';
import {useLoadActiveLockerrooms,} from 'module/lockerRoom/useLoadActiveLockerrooms';
import {useLoadActiveUsersInLockerroom} from 'module/lockerRoom/useLoadActiveUsersInLockerroom';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {useUser} from 'module/user/useUser';

import demoCampaign from './assets/demodata/campaign.json';
import demoInfo from './assets/demodata/info.json';
import {loadCampaign} from './store/campaign';
import {loadInfo} from './store/info';
import {useCampaignStore, useInfoStore} from './store';

const Periodic = () => {
    const setInfo = useInfoStore(state => state.setInfo);
    const setCampaign = useCampaignStore(state => state.setCampaign);
    const isLockerroomActive = useLockerRoomStore((state) => state.active);
    const getUser = useUser();

    const loadActiveLockerrooms = useLoadActiveLockerrooms();
    const loadActiveUsersInLockerroom = useLoadActiveUsersInLockerroom();

    const load = async () => {
        // gives the lockerroom start button a flickering but prevents user from clicking when loading
        // setIsLoadingLockerrooms(true);
        const info = await loadInfo();
        setInfo(isDemo() ? demoInfo : info);

        const campaign = await loadCampaign();
        setCampaign(isDemo() ? demoCampaign : campaign);

        if (!isLockerroomActive) {
            await loadActiveLockerrooms();
        } else {
            await loadActiveUsersInLockerroom();
        }
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(async () => {
        load();
    }, 5 * 1000);

    useInterval(async () => {
        getUser();
    }, 1000);

    return null;
};

export default Periodic;