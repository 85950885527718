import React, {useEffect, useImperativeHandle, useState} from 'react';
import {forwardRef} from 'react';

import {Avatar, Box, Grid, Stack, Typography} from '@mui/material';

import {clearObjectEvents} from 'canvas/canvas-handlers';
import {createPlayer} from 'canvas/Drawer/Player';
import {createPlayerCircle} from 'canvas/Drawer/PlayerCircle';
import {useCanvasStore} from 'canvas/zustand';
import {useTranslation} from 'hooks';
import {useSettingsStore} from 'module/settings/zustand';

import {getPlayerInfo, getPlayerName, getPlayerNumber, setPlayerInfo, setPlayerName} from '../../canvas/Drawer/player-utils';

import {Form, FormField, useForm} from 'form';

const styles = {
    formContainer: {
        mt: 2
    }
};

// eslint-disable-next-line react/display-name
const Edit = forwardRef(({player}, ref) => {
    // const selectedTeam = useSettingsStore.getState().getSelectedTeam();
    const translate = useTranslation();
    const avatarCrop = '?w=100&h=100&m=crop';

    const playersFromSettings = useSettingsStore(state => state.players);

    const [errors] = useState({});
    const [formPlayer, setFormPlayer] = useState({_id: player.custom.playerId,
        name: getPlayerName(player),
        number: getPlayerNumber(player),
        info: getPlayerInfo(player),
        image: playersFromSettings[playersFromSettings.findIndex(p => p._id === player?.custom?.playerId)]?.image
    });
    const [avatar, setAvatar] = useState(formPlayer?.image?.url + avatarCrop);

    useEffect(() => {
        setAvatar(formPlayer?.image?.url + avatarCrop);
    }, [formPlayer]);

    const rhf = useForm(formPlayer, errors);
    useImperativeHandle(ref, () => ({
        submit() {
            rhf.methods.handleSubmit(onSubmit)();
        }
    }));
    const canvas = useCanvasStore(state => state.canvas);

    const reCreatePlayer = (formData) => {
        console.log('reCreatePlayer', formData, player.angle, player._objects[0]);
        const angle = player.angle || player._objects[0].angle;

        if (canvas) {
            clearObjectEvents(canvas);
            canvas?.remove(player);

            if (player.custom.subType === 'player') {
                createPlayer(canvas, 'player', player.custom.colors, player.custom.size, player.left, player.top, formData.number, player.custom.team,
                    (canvas, newPlayer) => {
                        newPlayer.canvas = canvas;
                        newPlayer.custom.playerId = formData._id;
                        setPlayerName(newPlayer, formData.name);
                        setPlayerInfo(newPlayer, formData.info);
                        canvas.add(newPlayer);
                        // to avoid losing focus to player
                        canvas.setActiveObject(newPlayer);
                    }, angle);
            }
            else {
                createPlayerCircle(canvas, player.custom.colors, player.custom.size ,player.left, player.top, formData.number ? formData.number : 1, true,
                    formData.image,
                    (canvas, newPlayer) => {
                        newPlayer.canvas = canvas;
                        newPlayer.custom.playerId = formData._id;
                        setPlayerName(newPlayer, formData.name);
                        setPlayerInfo(newPlayer, formData.info);
                        canvas.add(newPlayer);
                        // to avoid losing focus to player  
                        canvas.setActiveObject(newPlayer);
                    }, angle);
            }
            
        }
        
    };

    const onSubmit = async (formData) => {
        console.log('succ submit');
        setFormPlayer(formData);
        reCreatePlayer(formData);
    };

    // const playerLabelDropDown = (player) => {
    //     let playerNumber = '(-1)';
    //     let label = 'namenlos';
    //     if (player?.firstname || player?.lastname)
    //         label = player?.firstname + ' ' + player?.lastname;

    //     if (player?.team?.number || player?.number) {
    //         playerNumber = `(${player?.team?.number || player?.number})`;
    //     }

    //     return label + ' ' + playerNumber;
    // };

    // const updatePlayer = (playerId) => {
    //     const actualPlayer = playersFromSettings[playersFromSettings.findIndex(p => p._id === playerId)];

    //     if (actualPlayer) {
    //         setFormPlayer(
    //             {_id: actualPlayer._id,
    //                 name: getPlayerNameNumberFromSettings(actualPlayer, selectedTeam?.prefs?.player?.display?.name),
    //                 number: getPlayerNameNumberFromSettings(actualPlayer, selectedTeam?.prefs?.player?.display?.number),
    //                 info: getPlayerInfo(player),
    //                 image: actualPlayer?.image
    //             });
    //     }
    // };

    return <Grid container direction={'column'}>
        <Form {...rhf} onSubmit={onSubmit}>
            <Stack sx={styles.formContainer} direction="column" justifyContent="space-between" spacing={2}>
                {/* {playersFromSettings.length > 0 ?
                    <SelectField
                        name="_id"
                        label={translate('board.player.settings_number')}
                        autoComplete="off"
                        onChange={e => updatePlayer(e.target.value)}
                        value={formPlayer?._id || ''}
                    >
                        {playersFromSettings.map((player, index) =>
                            <MenuItem
                                key={index}
                                value={player._id}>
                                {playerLabelDropDown(player)}
                            </MenuItem>)}
                    </SelectField>
                    : null} */}
                <FormField
                    name="number"
                    variant="standard"
                    margin="normal"
                    label={translate('board.player.number')}
                    maxLength={2}
                    autoComplete="off"
                    fullWidth={true}
                />
                <FormField
                    name="name"
                    variant="standard"
                    margin="normal"
                    label={translate('global.name')}
                    autoComplete="off"
                    fullWidth={true}
                />
                <FormField
                    name="info"
                    variant="standard"
                    margin="normal"
                    label={translate('global.info')}
                    autoComplete="off"
                    fullWidth={true}
                />
                {formPlayer?.image?.url && (<>
                    <FormField
                        type="hidden"
                        name="image"
                    />
                    <Box direction="column" alignItems="start" ml={0.5}>
                        <Typography color="secondary" mb={1} variant="body2">Avatar</Typography>
                        <Avatar alt="Alter ego" src={avatar} />
                    </Box>
                </>
                )}
            </Stack>

        </Form>
    </Grid>;
});

export default Edit;
