import PropTypes from 'prop-types';

import {v4 as uuidv4} from 'uuid';

import {fabric} from 'fabric';

import {isCustom, limitReached, setLastObjectActive} from '../canvas-helper';

import {teams} from './Player';
import {
    createPlayerNumber,
    getCircleSizeOptions,
    getNextPlayerNumber
} from './player-utils';

const PlayerCircle = ({canvas, colors, size}) => {
    canvas.on({
        'mouse:down': (o) => {
            if (limitReached(canvas)) {
                return;
            }

            if (canvas.getActiveObject()) {
                if (isCustom(canvas.getActiveObject())) {
                    return;
                }

                canvas.discardActiveObject();
            }

            const pointer = canvas.getPointer(o.e);
            const nextNumber = getNextPlayerNumber(canvas, colors).toString();
            createPlayerCircle(
                canvas,
                colors,
                size,
                pointer.x - 20,
                pointer.y - 20,
                nextNumber,
                teams.HOME,
                null,
                (canvas, group) => {
                    canvas.add(group);
                    setLastObjectActive(canvas);
                }
            );
        },
    });
};

export const createPlayerCircle = (
    canvas,
    colors,
    size,
    posX,
    posY,
    playerNumber,
    team,
    playerImage,
    onComplete,
    angle
) => {
    const sizeValue = getCircleSizeOptions(size);
    const circle = new fabric.Circle({
        strokeWidth: 3,
        fill: colors.value1,
        stroke: colors.value2,
        //radius: 20,
        radius: sizeValue.size,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
    });

    const avatarCrop = '?w=100&h=100&m=crop';

    if (playerImage && size > 2) {
        fabric.Image.fromURL(playerImage.url + avatarCrop, (imageCircle) => {
            imageCircle.clipPath = circle;
            imageCircle.originX = 'center';
            imageCircle.originY = 'center';
            imageCircle.custom = {type: 'player-image', id: uuidv4()};
            
            // imageCircle.set({
            //     stroke : 'green',
            // });
            // imageCircle.scaleToHeight(80);
            // imageCircle.scaleToWidth(80);
            // let textColor = '#000000';
            // textColor = getCircleTextColor(fill,textColor);
            
            const textSize = 28 + size;
            const number = createPlayerNumber(
                playerNumber.toString(),
                colors.textColor,
                textSize
            );
            
            const border = new fabric.Circle({
                strokeWidth: 4,
                fill: 'rgba(0,0,0,0)',
                stroke: colors.value1,
                radius: sizeValue.size + 6,
                hasBorders: false,
                hasControls: false,
                originX: 'center',
                originY: 'center',
            });

            // adjust number
            number.top = ((border.height / 2) + 2) * -1;
            number.left = ((border.width / 2) + 2) * -1;

            // to adjust width so name of player can be shown in center
            const cloneNum = createPlayerNumber(
                playerNumber.toString(),
                'rgba(0,0,0,0)',
                textSize
            );
            cloneNum.top = ((border.height / 2) + 2) * -1;
            cloneNum.left = ((border.width / 2) + 2);

            // add border
            const avatar = new fabric.Group([border, imageCircle, cloneNum], {
                custom: {
                    type: 'player-avatar',
                    id: uuidv4(),
                }
            });
            
            const player = new fabric.Group([avatar, number], {
                custom: {
                    type: 'player',
                    subType: 'playerCircle',
                    id: uuidv4(),
                    team,
                    colors,
                    size,
                },
                angle: angle || 0,
                left: posX,
                top: posY,
                hasBorders: false,
                hasControls: false,
            });
        
            onComplete && onComplete(canvas, player);
        });
    }
    else {
        const textSize = 28 + size;
        const number = createPlayerNumber(
            playerNumber.toString(),
            colors.textColor,
            textSize
        );
    
        const player = new fabric.Group([circle, number], {
            custom: {
                type: 'player',
                subType: 'playerCircle',
                id: uuidv4(),
                team,
                colors: colors,
                size,
            },
            angle: angle || 0,
            left: posX,
            top: posY,
            hasBorders: false,
            hasControls: false,
        });
    
        onComplete && onComplete(canvas, player);
    }

};

PlayerCircle.propTypes = {
    canvas: PropTypes.object.isRequired,
};

export default PlayerCircle;
