import React from 'react';

import {Box, Stack} from '@mui/material';

const styles = {
    puller: {
        display: 'block',
        width: '2px',
        height: '50px',
        backgroundColor: 'grey.light',
        marginRight: '5px'
    },
};

const Puller = ({onClick, ...rest}) => {
    return <Stack direction="row" justifyContent="flex-end" onClick={onClick} {...rest}>
        <Box sx={styles.puller}></Box>
        <Box sx={styles.puller}></Box>
    </Stack>;
};

export default Puller;