import React from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '@mui/material';

export const styles = {
    accordionBlock: {
        color: 'white.main',
        display: 'flex',
        fontSize: '12px',
        padding: '0',
        margin: '0',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'grey.darkest',
        borderLeft: 0
    },
    accordionBlockExpanded: {
        borderLeft: '10px solid',
        borderLeftColor: 'green.main',
        backgroundColor: 'grey.darkest',
    },
    accordionHeaderBlock: {
        minHeight: 70,
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '0 20px 0 20px',
        backgroundColor: '#191919 !important',
        borderTop: '1px solid',
        borderTopColor: 'grey.darker',
    },
    accordionExpandButton: {
        color: 'grey.lightest',
        borderRadius: 0,
        backgroundColor: 'grey.darkest',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '40px',
        height: '40px',
    },
    accordionDetails: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
        backgroundColor: 'grey.darkest',
        borderBottom: 'none',
    },
};

export const CustomAccordion = ({children, expandedStyle = false, ...rest}) => {
    return <Accordion
        TransitionProps={{unmountOnExit: true}}
        disableGutters
        sx={expandedStyle ? styles.accordionBlockExpanded : styles.accordionBlock}
        {...rest}
    >
        {children}
    </Accordion>;
};

export const CustomAccordionSummary = ({children, ...rest}) => {
    return <AccordionSummary
        sx={styles.accordionHeaderBlock}
        {...rest}
    >
        {children}
    </AccordionSummary>;
};

export const CustomAccordionDetails = ({children}) => {
    return <AccordionDetails sx={styles.accordionDetails}>
        {children}
    </AccordionDetails>;
};
