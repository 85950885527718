import React, {useState} from 'react';

import {Box, Stack, Typography} from '@mui/material';

import {guestLineup, homeLineup} from 'assets/lineups';
import {useAction} from 'canvas/canvas-actions';
import {teams} from 'canvas/Drawer/Player';
import {Text} from 'components';

import FlyoutButton from './FlyoutButton';

const styles = {
    lineupButtonHolder: {
        padding: '10px 5px',
        aspectRatio: '1/1',

        width: {
            mobile: '24%',
            desktop: '11.5%',
        },
        margin: '0.5%',
        backgroundColor: 'grey.darkest',
    },
    iconBox: {
        width: '100%',
    },
    icon: {
        display: 'block',
        opacity: 1,
        height: '80px',
    },
    iconTitle: {
        fontSize: {
            mobile: '15px',
            desktop: '16px',
        },
    },
    titleHolder: {
        marginLeft: '0.5%',
    },
};

const LineupTool = ({onSelect}) => {
    const [direction, setDirection] = useState(teams.HOME);

    const {dispatch: onAction} = useAction();

    const onClick = (index) => {
        onAction('lineup', direction, direction === teams.HOME ? homeLineup[index] : guestLineup[index]);
        onSelect();
    };

    return <>
        <Box>
            <Typography sx={styles.titleHolder} variant="h1" color="grey.mainlight" mb={3}><Text>board.toolbar.lineup</Text></Typography>
            <Typography
                sx={styles.titleHolder} variant="h2" color="grey.mainlight"
                mb={1}><Text>board.toolbar.playing_direction</Text>:</Typography>
        </Box>

        <Stack direction="row" flexWrap="wrap" useFlexGap={true}>
            <FlyoutButton
                icon="ArrowUpward" selected={direction === teams.HOME}
                onClick={() => setDirection(teams.HOME)}/>
            <FlyoutButton
                icon="ArrowDownward" selected={direction === teams.GUEST}
                onClick={() => setDirection(teams.GUEST)}/>
        </Stack>

        <Box mt={4}>
            <Typography sx={styles.titleHolder} variant="h2" color="grey.mainlight" mb={1}><Text>board.toolbar.lineup</Text>
                <Text>board.toolbar.position</Text>:</Typography>
        </Box>

        <Stack direction="row" flexWrap="wrap" useFlexGap={true}>
            {homeLineup.map((l, index) => <Stack
                key={l.name}
                onClick={() => onClick(index)}
                gap={1}
                sx={styles.lineupButtonHolder}
                alignItems="center"
                justifyContent="center"
            >
                <Box sx={styles.iconBox} display="flex" justifyContent="center">
                    <l.Icon 
                        style={styles.icon}
                    />
                </Box>
                <Typography sx={styles.iconTitle} align="center">{l.name}</Typography>
            </Stack>)}
        </Stack>
    </>;
};

export default LineupTool;