import React, {useEffect, useRef, useState} from 'react';
import ReactKeyboard from 'react-simple-keyboard';

import {Box, useMediaQuery, useTheme} from '@mui/material';

import {useKeyboardStore} from 'store';

import 'react-simple-keyboard/build/css/index.css';

import german from 'simple-keyboard-layouts/build/layouts/german';

let layouts = {
    ...german.layout,
    numpad: ['1 2 3', '4 5 6', '7 8 9', '0 0 {bksp}'],
};

layouts.default[4] = '.com @ {space} {clear}';

const display = {
    '{bksp}': '<-',
    '{clear}': 'clear',
};

const styles = {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 100000,

    '& .keyboard-full': {
        width: 800,
        '& [data-skbtn="{clear}"]': {
            maxWidth: 100,
        },
    },
    '& .hg-layout-numpad': {
        width: 200,
    },
    '& .hg-theme-default': {
        backgroundColor: 'white.main',
    },
    '& .hg-button': {
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'grey.darker',
        color: 'white.main',
        fontWeight: '700',
    },
    '& .hg-button:active': {
        backgroundColor: 'white.main',
        color: 'grey.darker',
    }
};

export const getKeyboardOptions = (e, setVal, opts = {}) => {
    const target = e.target;
    const offsetY = 10;
    const rect = target.getBoundingClientRect();

    return {
        value: target.value,
        top: rect.top + rect.height + offsetY,
        left: 0,
        layout: 'default',
        hideOnEnter: true,
        onInput: (input, caretPosition) => {
            setVal(input);

            setTimeout(() => {
                if (caretPosition !== null && target.setSelectionRange) {
                    target.focus();
                    target.setSelectionRange(caretPosition, caretPosition);
                }
            }, 1);
        },
        ...opts,
    };
};

const Keyboard = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const visible = useKeyboardStore((state) => state.visible);
    const layout = useKeyboardStore((state) => state.layout);
    const value = useKeyboardStore((state) => state.value);
    const maxLength = useKeyboardStore((state) => state.maxLength);
    const hideOnEnter = useKeyboardStore((state) => state.hideOnEnter);
    const top = useKeyboardStore((state) => state.top);
    const left = useKeyboardStore((state) => state.left);
    const onInput = useKeyboardStore((state) => state.onInput);
    const hide = useKeyboardStore((state) => state.hide);

    const [layoutName, setLayoutName] = useState('default');
    const [isShift, setIsShift] = useState(false);
    const [isShiftLock, setIsShiftLock] = useState(false);

    const keyboardRef = useRef(null);

    if (typeof top === 'number') {
        styles.top = top;
    }

    if (typeof left === 'number') {
        styles.left = left;
    }

    const onKeyboardInput = (input) => {
        const caretPosition = keyboardRef.current.caretPosition;
        onInput(input, caretPosition);
    };

    const onKeyPress = (button) => {
        if (button === '{clear}') {
            keyboardRef.current.clearInput();
            onInput('');
        }

        if (button === '{shift}') {
            setIsShift(true);
        }

        if (button === '{lock}') {
            setIsShift(!isShiftLock);
            setIsShiftLock(!isShiftLock);
        }

        if (button === '{enter}') {
            if (hideOnEnter) {
                hide();
            }
        }

        if (button !== '{shift}' && button !== '{lock}') {
            if (isShift && !isShiftLock) {
                setIsShift(false);
            }
        }
    };

    useEffect(() => {
        if (keyboardRef.current !== null) {
            keyboardRef.current.replaceInput({default: value});
        }
    }, [value]);

    useEffect(() => {
        setLayoutName(layout);
    }, [layout]);

    useEffect(() => {
        setLayoutName(isShift ? 'shift' : 'default');
    }, [isShift]);

    if (!visible || isMobile) {
        return null;
    }

    return (
        <Box sx={styles}>
            <ReactKeyboard
                keyboardRef={(r) => (keyboardRef.current = r)}
                layout={layouts}
                layoutName={layoutName}
                display={display}
                disableCaretPositioning={false}
                maxLength={maxLength}
                mergeDisplay={true}
                newLineOnEnter={!hideOnEnter}
                tabCharOnTab={false} // disable tabs
                preventMouseDownDefault={true} // prevents focus loss on input field
                baseClass="keyboard-full"
                onChange={onKeyboardInput}
                onKeyPress={onKeyPress}
            />
        </Box>
    );
};

export default Keyboard;
