import React, {useEffect, useState} from 'react';

import {Box, Button, DialogActions, DialogContent, LinearProgress, Slide, Stack, TextField, Typography} from '@mui/material';

import {v4 as uuidv4} from 'uuid';

import {Dialog, Text} from 'components';
import {useTranslation} from 'hooks';
import {isProduction} from 'lib/env';
import {isDevelopment,isOfflineDevelopment} from 'lib/env';
import {connectionHandler} from 'module/lockerRoom/api';
import {PlayerList} from 'module/settings';
import {useLoadPlayers} from 'module/settings/useSettings';
import {useUserStore} from 'module/user';
import {useSnackbarStore} from 'store';

import {useSettingsStore} from '../settings/zustand';

import {useLockerRoomStore} from './zustand';

const styles = {
    dialogContentBlock: {
        margin: 0,
        padding: '5px 20px 20px 20px',
        flexGrow: 0,
    },
    membersContainer: {
        padding: '8px 20px',
    },
    membersContainerInline: {
        backgroundColor: 'grey.dark',
        borderRadius: '5px',
        padding: '3px 0 0 0',
    },
    dialogContentBlockNoPaddingBottom: {
        padding: '0 20px',
    },
    textField: {
        border: '1px solid',
        borderColor: 'white.main',
        borderRadius: '5px',
        width: '100%',
    },
    membersTitle: {
        color: 'white.main',
        margin: '10px',
        textAlign: 'center',
    },
    dialogActions: {
        padding: '10px 20px',
    },
    dialogActionButton: {
        backgroundColor: 'green.lockerroom',
        borderRadius: '5px',
        color: '#fff',
        padding: '15px 12px',
        width: '100%',
        textTransform: 'none',
        fontSize: '15px',
        '&:hover': {
            backgroundColor: 'green.lockerroom',
        }
    },
    dialogActionSelectButton: {
        backgroundColor: 'orange.main',
        color: '#fff',
        padding: '10px 20px',
        width: '100%',
        textTransform: 'none',
        fontSize: '15px',
        '&:hover': {
            backgroundColor: 'orange.main',
        }
    },
    dialogActionButtonDisabled: {
        backgroundColor: 'grey.dark',
        borderRadius: '5px',
        color: '#fff !important',
        padding: '10px 20px',
        width: '100%',
        textTransform: 'none',
        fontSize: '15px',
        '&:hover': {
            backgroundColor: 'grey.dark',
        }
    },
    // noMemberContainer: {
    //     padding: '5px 20px 20px 20px',
    // },
    // noMemberText: {
    //     color: '#fff',
    //     fontWeight: '700',
    // }

};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StartDialog = ({open, handleClose}) => {
    const translate = useTranslation();
    const [selected, setSelected] = useState(new Set());
    const title = useLockerRoomStore((state) => state.title);
    const setTitle = useLockerRoomStore((state) => state.setTitle);

    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    // const active = useLockerRoomStore((state) => state.active);

    const user = useUserStore((store) => store.user);
    const checkPermission = useUserStore((store) => store.checkPermission);
    const showInfo = useSnackbarStore(state => state.show);
    const setRoomId = useLockerRoomStore((state) => state.setRoomId);
    const setOpening = useLockerRoomStore((state) => state.setOpening);
    const setInvitedUsers = useLockerRoomStore((state) => state.setInvitedUsers);
    const opening = useLockerRoomStore((state) => state.opening);
    const otherPlayers = useSettingsStore((state) => state.players)?.filter((player) => player._id !== user?.id);

    const loadPlayers = useLoadPlayers();

    useEffect(() => {
        if (selectedTeam) {
            loadPlayers(selectedTeam);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam]);

    const onSelect = (player) => {
        const newSelected = new Set(selected);
        newSelected.has(player) ? newSelected.delete(player) : newSelected.add(player);
        setSelected(newSelected);
    };

    const selectAll = () => {
        setSelected(new Set(otherPlayers));       
    };

    const onCreate = async (invited) => {
        // if (selected.size === 0) {
        //     showInfo('lockerroom.message.player_missing', {severity: 'warning'});
        //     return;
        // }

        if (title.length === 0) {
            showInfo('lockerroom.message.title', {severity: 'warning'});
            console.log('Bitte Titel für Lockerroom vergeben');
            return;
        }

        if (!checkPermission(selectedTeam, 'lockerrooms', 'create')) {
            showInfo('lockerroom.message.trainer_only', {severity: 'warning'});
            console.log('Nur Trainer dürfen einen Lockerroom erstellen!');
            return;
        }

        console.log('click create', user, [...invited].map((inv) => inv._id));
        let roomId = uuidv4();

        if (isOfflineDevelopment()) {
            //local testing gets room id 1
            roomId = '1';
        }

        setRoomId(roomId);

        await connectionHandler({selectedTeam, user, roomId, title, invited:[...invited].map((inv) => inv._id), action: 'create'});
        setOpening(true);
        setInvitedUsers([...invited]);

    };

    return <Dialog fullScreen TransitionComponent={Transition} open={open} handleClose={handleClose} title={translate('lockerroom.action.start')}>
        {opening && <Stack>
            <LinearProgress/>
        </Stack>}
        {!opening &&
        <Box>

            {isDevelopment() || otherPlayers.length > 0 ?
                <>
                    {/* Lockerroom-Title */}
                    <DialogContent sx={styles.dialogContentBlock}>
                        <TextField
                            name="title"
                            label="Titel"
                            variant="outlined"
                            size="medium"
                            value={title}
                            sx={styles.textField}
                            onChange={e => setTitle(e.target.value)}/>
                    </DialogContent>

                    {/* Alle selektieren Button */}
                    <DialogActions sx={styles.dialogActions}>
                        <Button disabled={selected.size === otherPlayers.length} sx={selected.size === otherPlayers.length ? styles.dialogActionButtonDisabled : styles.dialogActionSelectButton} onClick={() => selectAll()}>{translate('lockerroom.action.select_all')}</Button>
                    </DialogActions>

                    {/* Lockerroom starten Button */}
                    <DialogActions sx={styles.dialogActions}>
                        <Button
                            disabled={isProduction() && selected.size === 0}
                            sx={isProduction() && selected.size === 0 ? styles.dialogActionButtonDisabled : styles.dialogActionButton}
                            onClick={() => onCreate(selected)}>
                            {title !== '' ? translate('lockerroom.action.start_selection') : translate('lockerroom.message.title_missing')}
                        </Button>
                    </DialogActions>

                    {/* Teilnehmer-Liste */}
                    <Box sx={styles.membersContainer}>
                        <Box sx={styles.membersContainerInline}>
                            <DialogContent sx={[styles.dialogContentBlock, styles.dialogContentBlockNoPaddingBottom]}>
                                <Typography sx={styles.membersTitle}><Text>lockerroom.action.select_member</Text>:</Typography>
                            </DialogContent>
                            <DialogContent sx={styles.dialogContentBlock}>
                                <PlayerList selected={selected} onSelect={onSelect} players={otherPlayers}/>
                            </DialogContent>
                        </Box>
                    </Box>

                    {/* Alle selektieren Button */}
                    <DialogActions sx={styles.dialogActions}>
                        <Button disabled={selected.size === otherPlayers.length} sx={selected.size === otherPlayers.length ? styles.dialogActionButtonDisabled : styles.dialogActionSelectButton} onClick={() => selectAll()}>{translate('lockerroom.action.select_all')}</Button>
                    </DialogActions>

                    {/* Lockerroom starten Button */}
                    <DialogActions sx={styles.dialogActions}>
                        <Button
                            disabled={isProduction() && selected.size === 0}
                            sx={isProduction() && selected.size === 0 ? styles.dialogActionButtonDisabled : styles.dialogActionButton}
                            onClick={() => onCreate(selected)}>
                            {title !== '' ? translate('lockerroom.action.start_selection') : translate('lockerroom.message.title_missing')}
                        </Button>
                    </DialogActions>
                </>
                :
                <Box sx={styles.noMemberContainer}>
                    <Typography sx={styles.noMemberText}>{translate('lockerroom.message.no_member')}</Typography>
                </Box>
            }

        </Box>}
    </Dialog>;
};

export default StartDialog;