import React, {forwardRef, useEffect, useState} from 'react';
import {useSwipeable} from 'react-swipeable';

import {Add as AddIcon} from '@mui/icons-material';
import {
    Collapse,
    Drawer as MuiDrawer, Fab,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Slide, Stack, Typography
} from '@mui/material';

import {
    ConfirmDialog,
    Dialog,
    MenuHeader,
    Puller,
    Text, useConfirmDialog
} from 'components';
import {useTranslation} from 'hooks';
import {dateFormat} from 'lib/datefns';
import {Group, GroupIconButton} from 'module/group/index';
import {useGroup, useGroupDelete, useGroupSort} from 'module/group/useGroup';
import {useSheet} from 'module/sheet/useSheet';
import {useLayoutStore, useSnackbarStore} from 'store';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.light'
    },
    listHolder: {
        padding: 0,
    },
    listItem: {
        width: '100%',
        borderBottom: '2px solid',
        borderBottomColor: 'grey.darkest2',
    },
    listItemActive: {
        width: '100%',
        backgroundColor: 'grey.darkest',
        borderBottom: '2px solid',
        borderBottomColor: 'grey.darkest2',
    },
    listContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    itemTextCollapse: {
        display: 'flex',
        flexGrow: 1,
    },
    actionButtonContainer: {
        paddingTop: '20px',
        paddingBottom: '20px',
        borderBottom: '2px solid',
        borderBottomColor: 'grey.darkest2',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    actionButton: {
        backgroundColor: 'grey.darker',
        fontSize: '30px',
        marginLeft: 2,
        marginRight: 2,
        ':hover': {
            backgroundColor: 'grey.darker',
        }
    },
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const GroupItem = ({group, isFirst, isLast, editGroup, onToggle, onEdit, onDelete}) => {
    const translate = useTranslation();

    const setShowSheetMenu = useLayoutStore(state => state.setShowSheetMenu);
    const setShowLoading = useLayoutStore(state => state.setShowLoading);

    const {setSelected: setSelectedGroup, selected} = useGroup();
    const {sheets, filterByGroup} = useSheet();

    const {sort: sortGroup, loading: loading2} = useGroupSort();

    const isActive = selected === group.id;
    const numSheets = filterByGroup(sheets, group.id).length;

    const txt = numSheets === 0 || numSheets > 1 ? 'board.sheet.sheets' : 'board.sheet.sheet';

    const handleSort = (group, direction) => {
        sortGroup(group, direction);
    };

    const handleSelect = (group) => {
        setSelectedGroup(group.id);
        setShowSheetMenu(true); // SheetMenu anzeigen
    };

    // Funktion, um das Klicken auf ein Gruppenelement zu verhindern, um das Menü zu schließen
    const preventClosingMenu = (event) => {
        event.stopPropagation();
    };
    
    const handlers = useSwipeable({
        onSwiped: (e) => onToggle(e, group),
        trackMouse: true,
    });

    useEffect(() => {
        setShowLoading(loading2);
    }, [loading2, setShowLoading]);

    return <ListItem sx={[isActive ? styles.listItemActive : styles.listItem]} onClick={preventClosingMenu}>
        <ListItemButton sx={styles.listContent} {...handlers}>
            <Collapse sx={styles.itemTextCollapse} in={group.id !== editGroup?.id} orientation="horizontal" collapsedSize={0} onClick={() => handleSelect(group)}>
                <ListItemText
                    primary={group.name}
                    secondary={`${dateFormat(group.created, 'dd.MM.yyyy, HH:mm')} | ${numSheets} ${translate(txt)}`}
                />
            </Collapse>
            <Puller onClick={(e) => onToggle(e, group)} pl={2}/>
            <Collapse in={group.id === editGroup?.id} orientation="horizontal" collapsedSize={0}>
                <Stack direction="row">
                    <GroupIconButton
                        icon="arrowup"
                        onClick={() => handleSort(group, 'up')}
                        disabled={isFirst}/>
                    <GroupIconButton
                        icon="arrowdown"
                        onClick={() => handleSort(group, 'down')}
                        disabled={isLast}/>
                    <GroupIconButton
                        icon="pencil"
                        onClick={() => onEdit(group)}
                    />
                    <GroupIconButton
                        icon="delete"
                        color="red.dark"
                        disabled={isActive}
                        onClick={() => onDelete(group)}
                    />
                </Stack>
            </Collapse>
        </ListItemButton>
    </ListItem>;
};

const Menu = () => {
    const showInfo = useSnackbarStore((state) => state.show);

    const showGroupMenu = useLayoutStore(state => state.showGroupMenu);
    const setShowGroupMenu = useLayoutStore(state => state.setShowGroupMenu);
    const setShowLoading = useLayoutStore(state => state.setShowLoading);
    const closeAll = useLayoutStore(state => state.closeMenu);

    const [editGroup, setEditGroup] = useState(null);
    const [showEdit, setShowEdit] = useState(false);

    const {groups, selected} = useGroup();

    const {del: deleteGroup, loading: loading1} = useGroupDelete();

    const handleEdit = (group) => {
        setEditGroup(group);
        setShowEdit(true);
    };

    const handleAdd = () => {
        setEditGroup(null);
        setShowEdit(true);
    };

    const handleDelete = (group) => {
        if (group.id !== selected) {
            deleteConfirm.show(group);
        } else {
            showInfo('board.group.validation.deletion_impossible', {severity: 'warning'});
        }
    };

    const deleteConfirm = useConfirmDialog(async (group) => {
        try {
            await deleteGroup(group);
            showInfo('board.group.action.delete_success');
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
        }
    },
    undefined,
    'board.group.action.confirm_delete_all');

    const closeEdit = () => {
        setShowEdit(false);
    };

    const closeMenu = () => {
        setShowGroupMenu(false);
    };

    const onSave = (group) => {
        if (editGroup === null) {
            setEditGroup(group);
        }

        closeEdit();
    };

    const toggleEditGroup = (e, group) => {
        if (e.dir === 'Left') {
            setEditGroup(group);
        } else if (e.dir === 'Right') {
            setEditGroup(null);
        } else {
            setEditGroup(editGroup === group ? null : group);
        }
    };

    useEffect(() => {
        setShowLoading(loading1);
    }, [loading1, setShowLoading]);

    return (
        <MuiDrawer PaperProps={{sx: styles.drawer}} anchor={'left'} open={showGroupMenu.show} onClose={closeMenu}>
            <ConfirmDialog {...deleteConfirm} />
            {/* Menu Header */}
            <MenuHeader
                text={<Text>board.menu.my_groups_sheets</Text>}
                handleBackButton={closeMenu}
                handleClose={closeAll}
            />

            <Stack sx={styles.actionButtonContainer} onClick={handleAdd} direction="row" alignItems="center">
                <Fab disableRipple sx={styles.actionButton}><AddIcon color="white"/></Fab>
                <Typography><Text>board.group.action.create</Text></Typography>
            </Stack>

            {showEdit && <Dialog
                maxWidth={false}
                pt={0}
                open={showEdit}
                TransitionComponent={Transition}
                onClose={closeEdit}
            >
                <Group group={editGroup} onSave={onSave} onCancel={closeEdit}/>
            </Dialog>}

            <List sx={styles.listHolder}>
                {groups.map((group, idx) => {
                    return <GroupItem 
                        key={group.id} 
                        group={group} 
                        isFirst={idx===0} 
                        isLast={idx === groups.length - 1} 
                        editGroup={editGroup} 
                        onToggle={toggleEditGroup} 
                        onEdit={handleEdit} 
                        onDelete={handleDelete}/>;
                })}
            </List>
        </MuiDrawer>
    );
};

export default Menu;