import {useCallback} from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

import {getPlayerNameNumberFromSettings} from 'module/settings/utils';
import {useSettingsStore} from 'module/settings/zustand';
import {useSheet} from 'module/sheet/useSheet';
import {useSnackbarStore} from 'store';
import {usePrefsStore} from 'store/prefs';

import {useAnalytics} from '../hooks';

import {createPlayer, teams} from './Drawer/Player';
import {getPlayerNumber, isOppositeTeamSameColor, isPlayerColorOnCanvas, playerOnCanvas, setPlayerName} from './Drawer/player-utils';
import {createPlayerCircle} from './Drawer/PlayerCircle';
import {canvasToJson, clear} from './canvas-helper';
import {useCanvasStore} from './zustand';

const CIRCLE_XFIX = 25;

const removeSelectedObject = (canvas) => {
    if (canvas.getActiveObjects().length === 0) {
        // setLastObjectActive(canvas);
    }

    canvas.getActiveObjects().forEach((o) => {
        canvas.remove(o);
    });

    canvas.discardActiveObject(null);
    // setLastObjectActive(canvas);

    canvas.renderAll();

};

const changePlayerColor = (playerType, player, colors) => {
    if (!player.custom.colors || player.custom.subType !== playerType) {
        return false;
    }

    if (player.custom.subType === 'player') {
        player._objects[0]._objects[0].set('fill', colors.value1); // trikot path1
        player._objects[0]._objects[1].set('fill', colors.value2); // trikot path2
        player._objects[1].set('fill', colors.textColor);
        player.custom.colors = colors;
        return true;
    }

    if (player.custom.subType === 'playerCircle') {
        player._objects[0].set('fill', colors.value1);
        player._objects[0].set('stroke', colors.value2);
        player._objects[1].set('fill', colors.textColor);
        player.custom.colors = colors;
        return true;
    }

    return false;
};

const drawSoccerLineup = (canvas, playerType, colors, playerSize, circleSize, team, lineup, settingsActive) => {
    // console.log('SETTINGS-ACTIVE', settingsActive);

    // ToDo: cleanup, by wrapping into a hook or passing as params, this is ugly
    const selectedTeam = useSettingsStore.getState().getSelectedTeam();
    const playersFromSettings = [...useSettingsStore.getState().players];
    const showInfo = useSnackbarStore.getState().show;

    if (isOppositeTeamSameColor(canvas, colors, team) ) {
        // ToDo: translate if converted to hook
        showInfo(`Farbe beim ${team === 'home' ? 'Gast' : 'Heim'}-Team schon vorhanden`);
        return;
    }

    // DO NOT allow lineup with ...
    // 1) SAME direction if already present (at least 1 player has this direction) not allowed on canvas
    // 2) SAME color for 2 teams (colors.value1 for trikots, colors for magnets)

    // PROBLEMS
    // - if you place a player (no team) it is marked as HOME team, so you cannot place home team then!
    // - initially prohibited, but you can change color of placed teams later and still get same color!!!
    // - you can change a team set (settings or not) to different color and lineup (to be discussed)
    let canvasAddLock = false;

    if (isPlayerColorOnCanvas(canvas, colors)) {
        canvasAddLock = true;
    }

    let players = canvas.getObjects().filter(e => {
        if (e.custom?.type !== 'player') {
            return false;
        }

        if (team === teams.HOME) {
            return e.custom?.team === team || e.custom?.team === undefined;
        } else {
            return e.custom?.team === team;
        }
    });

    const gk = players.find(p => getPlayerNumber(p) === '1'); //  TODO refactor : get goalkeeper
    players = players.filter(p => getPlayerNumber(p) !== '1'); // remove goalkeeper from team

    const scaleUp = 1 / canvas.getZoom();
    const factorX = canvas.width * scaleUp / 19.5;
    const factorY = canvas.height * scaleUp / 36;

    let playerNumber = players.length + 1;

    const gkPos = {
        left: factorX * 9,
        top: team === teams.HOME ? factorY * 34 : factorY
    };

    // SETTINGS LINEUP: get first player that is marked as goalie
    if (settingsActive) {
        let gkIndex = playersFromSettings.findIndex((player) => (player.team?.positions?.includes('goal')) );

        if (gkIndex) {
            let gk = playersFromSettings.splice(gkIndex, 1)[0];

            if (gk) {
                let myGoalieOnCanvas = playerOnCanvas(canvas, gk._id);

                if (myGoalieOnCanvas) {
                    myGoalieOnCanvas?.animate(gkPos, {
                        duration: 800,
                        onChange: canvas.renderAll.bind(canvas),
                    });
                }
                else if (!canvasAddLock) {
                    const playerName = getPlayerNameNumberFromSettings(gk, selectedTeam?.prefs?.player?.display?.name);
                    const playerNumber = getPlayerNameNumberFromSettings(gk, selectedTeam?.prefs?.player?.display?.number);

                    if (playerType === 'playerCircle') {
                        createPlayerCircle(canvas, colors, circleSize, gkPos.left + CIRCLE_XFIX, gkPos.top, playerNumber, team, selectedTeam?.prefs?.player?.image ? gk.image : null, (canvas, player) => {
                            player.custom.playerId = gk._id;
                            // player.custom.teamId = selectedTeam?._id;
                            setPlayerName(player, playerName);
                            canvas.add(player);
                        });
                    } else {
                        createPlayer(canvas, 'player', colors, playerSize, gkPos.left, gkPos.top, playerNumber, team, (canvas, player) => {
                            player.custom.playerId = gk._id;
                            // player.custom.teamId = selectedTeam?._id;
                            setPlayerName(player, playerName);
                            canvas.add(player);
                        });
                    }
                }
            }
        }
    }
    // NORMAL lineup: place goalkeeper
    else {
        if (gk)
        {
            gk.animate(gkPos, {
                duration: 800,
                onChange: canvas.renderAll.bind(canvas),
            });

            // circles or players are changed
            if (!changePlayerColor(playerType, gk, colors)) {
                console.log('recreate player');
                // circle -> players or players -> circles
                // need to remove player object and re-create other player/circle
                canvas.remove(gk);

                if (playerType === 'playerCircle') {
                    createPlayerCircle(canvas, colors, circleSize,gkPos.left + CIRCLE_XFIX, gkPos.top, 1, team, null, (canvas, player) => {
                        canvas.add(player);
                    });
                } else {
                    createPlayer(canvas, 'player', colors, playerSize, gkPos.left, gkPos.top, 1, team, (canvas, player) => {
                        canvas.add(player);
                    });
                }
            }
        } else if (!canvasAddLock) {
            if (playerType === 'playerCircle') {
                createPlayerCircle(canvas, colors, circleSize,gkPos.left + CIRCLE_XFIX, gkPos.top, 1, team, null, (canvas, player) => {
                    canvas.add(player);
                });
            } else {
                createPlayer(canvas, 'player', colors, playerSize, gkPos.left, gkPos.top, 1, team, (canvas, player) => {
                    canvas.add(player);
                });
            }
        }
    }

    // SETTINGS & NORMAL ANIMATE LINEUP: value used to get max players from lineup
    lineup.positions.slice(0, settingsActive && selectedTeam?.prefs?.player?.count ? selectedTeam?.prefs?.player?.count-1 : 10).forEach(pos => {

        let posX = factorX * pos.x;
        let posY = factorY * pos.y;

        if (settingsActive) {
            let nextPlayer = playersFromSettings.shift();

            if (nextPlayer) {
                let myPlayerOnCanvas = playerOnCanvas(canvas, nextPlayer._id);

                if (myPlayerOnCanvas) {
                    myPlayerOnCanvas?.animate({left: posX, top: posY}, {
                        duration: 800,
                        onChange: canvas.renderAll.bind(canvas),
                    });
                }
                else if (!canvasAddLock) {
                    const playerName = getPlayerNameNumberFromSettings(nextPlayer, selectedTeam?.prefs?.player?.display?.name);
                    const playerNumber = getPlayerNameNumberFromSettings(nextPlayer, selectedTeam?.prefs?.player?.display?.number);
                    let image = selectedTeam?.prefs?.player?.image ? nextPlayer.image : null;

                    // if (!isNumberOnCanvas(canvas, playerNumber))
                    if (playerType === 'playerCircle') {
                        createPlayerCircle(canvas, colors, circleSize, posX + CIRCLE_XFIX, posY, playerNumber, team, image, (canvas, player) => {
                            player.custom.playerId = nextPlayer._id;
                            // player.custom.teamId = selectedTeam?._id;
                            setPlayerName(player, playerName);
                            canvas.add(player);
                        });
                    } else {
                        createPlayer(canvas, 'player', colors, playerSize, posX, posY, playerNumber, team, (canvas, player) => {
                            player.custom.playerId = nextPlayer._id;
                            // player.custom.teamId = selectedTeam?._id;
                            setPlayerName(player, playerName);
                            canvas.add(player);
                        });
                    }
                }

            }
        }
        else {
            if (players.length) {
                let player = players[0];
                // setPlayerName(player, 'test');
                player.animate({left: posX, top: posY}, {
                    duration: 800,
                    onChange: canvas.renderAll.bind(canvas),
                });

                // if circles or players positions are changed -> update color
                if (!changePlayerColor(playerType, player, colors))
                {
                    // cirlce -> players or players -> circles
                    // need to remove player object and re-create other player/circle
                    let storedPlayerNumber = getPlayerNumber(player);
                    canvas.remove(player);

                    if (playerType === 'playerCircle') {
                        createPlayerCircle(canvas, colors, circleSize, posX + CIRCLE_XFIX, posY, storedPlayerNumber, team, null, (canvas, player) => {
                            canvas.add(player);
                        });
                    } else {
                        createPlayer(canvas, 'player', colors, playerSize, posX, posY, storedPlayerNumber, team, (canvas, player) => {
                            canvas.add(player);
                        });
                    }
                }

                players.shift();
            } else if (!canvasAddLock) {
                playerNumber++;

                if (playerType === 'playerCircle') {
                    createPlayerCircle(canvas, colors, circleSize, posX + CIRCLE_XFIX, posY, playerNumber, team, null, (canvas, player) => {
                        canvas.add(player);
                    });
                } else {
                    createPlayer(canvas, 'player', colors, playerSize, posX, posY, playerNumber, team, (canvas, player) => {
                        canvas.add(player);
                    });
                }
            }
        }

    });

};

/**
 * A function that returns a dispatch function for handling actions related to canvas drawing and manipulation.
 * Canvas only, do not anything else, like storing data etc
 * @returns {{dispatch: function}} The dispatch function for handling actions.
 */
export const useAction = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const canvas = useCanvasStore(state => state.canvas);
    const pushCanvas = useCanvasStore(state => state.pushCanvas);
    const previousCanvasState = useCanvasStore(state => state.previousCanvasState);
    const nextCanvasState = useCanvasStore(state => state.nextCanvasState);
    const setDirty = useCanvasStore(state => state.setDirty);

    const {frameIndex} = useSheet();

    const prefs = usePrefsStore(state => state.prefs.player);
    const color = usePrefsStore(state => state.color);

    const background = usePrefsStore(state => state.background);

    const settingsActive = useSettingsStore(state => state.active);

    const editable = frameIndex === 0;

    const sendAnalytics = useAnalytics();

    const dispatch = useCallback((action, ...rest) => {

        if (action === 'redo') {
            nextCanvasState({isMobile});
        }

        if (action === 'previous') {
            previousCanvasState({isMobile});
        }

        if (action === 'push') {
            pushCanvas(canvasToJson(canvas));
        }

        if (editable && action === 'remove') {
            if (canvas.getActiveObjects().length > 0) {
                removeSelectedObject(canvas);
                pushCanvas(canvasToJson(canvas));
            }
        }

        if (action === 'lineup') {
            setDirty(true);
            sendAnalytics('action', action, {'homeguest': rest[0], 'formation': rest[1]['name']});
            console.log('lineup', prefs, color);
            drawSoccerLineup(canvas, prefs.type, color, prefs.size, prefs.size, ...rest, settingsActive);
            pushCanvas(canvasToJson(canvas));
        }

        if (action === 'clear') {
            pushCanvas(canvasToJson(canvas));
            clear(canvas, background);
        }

        if (action !== 'lineup') {
            sendAnalytics('action', action);
        }

    }, [editable, nextCanvasState, isMobile, previousCanvasState, canvas, pushCanvas, prefs, setDirty, color, sendAnalytics, settingsActive, background]);

    return {dispatch};
};
