import {useLockerroom} from './useLockerroom';
import useMediaSoup from './useMediaSoup';

const LockerroomHooks = () => {
    
    useLockerroom();
    useMediaSoup();
    return null;
};

export default LockerroomHooks;
