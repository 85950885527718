import {getEnv} from 'lib/env';

import {io} from 'socket.io-client';

const lockerRoom = io(getEnv('LOCKERROOM_URL'), {autoConnect: false, auth: {appId: ''}});
lockerRoom.allowSendImage = true;

lockerRoom.request = function request(type, data = {}) {
    return new Promise((resolve, reject) => {
        console.log(type);
        console.log(data);
        console.log('server lockerroom request', type, data);
        lockerRoom.emit(type, data, (data) => {
            console.log('LOCKER_REQUEST', data);

            if (data?.error) {
                reject(data?.error);
            } else {
                resolve(data);
            }
        });
    });
};

export default lockerRoom;
