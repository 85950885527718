import {create} from 'zustand';

import {fabric} from 'fabric';
import {middleware} from 'store/zustand';

import {canvasReviver, canvasToJson} from './canvas-helper';

export const useCanvasStore = create(middleware((set, get) => ({
    canvas: null,
    dirty: false,
    canvasInit: false,
    canvasStates: [],
    canvasStatesIndex: -1,
    set: canvas => set({canvas}),
    setDirty: dirty => set({dirty}),
    setCanvasInit: canvasInit => set({canvasInit}),
    clearCanvasStates: () => 
        set(() => (
            {canvasStates: [], canvasStatesIndex: -1}
        )),
    resetCanvasStates: (canvasState) => 
        set(() => ({
            dirty: false,
            canvasStates: [canvasState],
            canvasStatesIndex: 0
        })),
    getCanvasStates: () => get().canvasStates,
    pushCanvas: (newCanvasJson) => {
        let canvasStates = get().canvasStates;
        let canvasStatesIndex = get().canvasStatesIndex;

        console.debug('pushCanvas', newCanvasJson);

        if (newCanvasJson === null) {
            return;
        }

        // need stringify check to prevent double pushing on erratic events like onAdd() firing twice with no reason for same action
        if (canvasStates.length === 0 || JSON.stringify(newCanvasJson) !== JSON.stringify(canvasStates.slice(-1)[0])) {
            // remove states higher than canvasStatesIndex (avoid alternate realities after undo)
            let removeHigherStates = (canvasStatesIndex + 1 < canvasStates.length);
    
            if (removeHigherStates) {
                set((state) => ({
                    canvasStates: [
                        ...state.canvasStates.slice(0, (canvasStatesIndex + 1) - canvasStates.length),
                        newCanvasJson
                    ],
                    canvasStatesIndex: canvasStatesIndex + 1
                }));
            }
            else
            {
                set((state) => ({
                    canvasStates: [
                        ...state.canvasStates,
                        newCanvasJson
                    ],
                    canvasStatesIndex: canvasStatesIndex + 1
                }));
            }
        }
    },
    nextCanvasState: ({isMobile}) => {
        let index = get().canvasStatesIndex + 1;

        if (index >= get().canvasStates.length) {
            return;
        }

        set(() => ({
            canvasStatesIndex: index
        }));

        let nextCanvas = get().canvasStates[index];
        const canvas = get().canvas;

        if (!nextCanvas) {
            return;
        }

        canvas.isLoading = true;

        // background different in next canvas -> load all objects and background
        if (!nextCanvas.backgroundImage || (nextCanvas?.backgroundImage?.src && nextCanvas.backgroundImage.src !== canvasToJson(canvas)?.backgroundImage?.src)) {
            canvas.loadFromJSON(nextCanvas, () => {
                canvas.renderAll.bind(canvas);
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            }, (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
        // only load objects
        else {
            canvas.remove(...canvas.getObjects());
            fabric.util.enlivenObjects(nextCanvas.objects, (objs) => {
                objs.forEach((item) => {
                    canvas.add(item);
                });
                canvas.requestRenderAll(); // Make sure to call once we're ready!
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            },'', (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
    },
    previousCanvasState: ({isMobile}) => {
        let index = get().canvasStatesIndex - 1;

        if (index < 0) {
            return;
        }

        set(() => ({
            canvasStatesIndex: index
        }));

        let previousCanvas = get().canvasStates[index];
        const canvas = get().canvas;

        if (!previousCanvas) {
            return;
        }

        canvas.isLoading = true;

        // background different in previous canvas -> load all objects and background
        if (!previousCanvas.backgroundImage || (previousCanvas?.backgroundImage?.src && previousCanvas.backgroundImage.src !== canvasToJson(canvas)?.backgroundImage?.src)) {
            canvas.loadFromJSON(previousCanvas, () => {
                canvas.renderAll.bind(canvas);
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            }, (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
        // only load objects
        else {
            canvas.remove(...canvas.getObjects());
            fabric.util.enlivenObjects(previousCanvas.objects, (objs) => {
                objs.forEach((item) => {
                    canvas.add(item);
                });
                canvas.requestRenderAll(); // Make sure to call once we're ready!
                canvas.isLoading = false;
                set(() => ({
                    dirty: true,
                }));
            },'', (json, element) => {
                canvasReviver(json, element, isMobile);
            });
        }
    },
}),{name: 'CanvasStore', debug: true}));
