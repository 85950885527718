import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

const defaultLang = {
    code: 'de',
    name: 'board.menu.language.german'
};

export const languages = [
    defaultLang,
    {
        code: 'en',
        name: 'board.menu.language.english'
    },
    {
        code: 'nl',
        name: 'board.menu.language.dutch'
    },
    {
        code: 'es',
        name: 'board.menu.language.spanish'
    },
    {
        code: 'fr',
        name: 'board.menu.language.french'
    }
];

export const useSettingsStore = create(persist(middleware((set, get) => ({
    quicklink: false,
    settingsQuicklink: false,
    players: [],
    teams: [],
    clients: [],
    active: false,
    selectedClient: null,
    selectedTeam: null,
    // availableLanguages: languages,
    selectedLanguage: defaultLang.code,
    setSelectedLanguage: language => set( produce({}, draft => {
        draft.selectedLanguage = language;
    })),
    getClient: clientId => {
        const index = get().clients.findIndex(client => client._id === clientId);

        if (index === -1) {
            return null;
        }

        return get().clients[index];
    },

    getTeam: teamId => {
        const index = get().teams.findIndex(team => team._id === teamId);

        if (index === -1) {
            return null;
        }

        return get().teams[index];
    },
    setActive: (active) => set(produce(draft => {
        draft.active = active;
    })),
    setQuicklink: (quicklink) => set(produce(draft => {
        draft.quicklink = quicklink;
    })),
    setSettingsQuicklink: (quicklink) => set(produce(draft => {
        draft.settingsQuicklink = quicklink;
    })),
    setSelectedTeam: team => set(produce(draft => {
        draft.selectedTeam = team;
    })),
    setSelectedClient: client => set(produce(draft => {
        draft.selectedClient = client;
    })),
    setPlayers: players => set(produce(draft => {
        // need sorted by inc player number for lineup!
        draft.players = players.sort((a,b) => a.number - b.number);
    })),
    setTeams: teams => set(produce(draft => {
        draft.teams = teams;
    })),
    updateTeam: team => set(produce(draft => {
        const index = draft.teams.findIndex(t => t._id === team._id);

        if (index !== -1) {
            console.log(team);
            draft.teams[index] = {
                ...draft.teams[index],
                modified: new Date(),
                ...team
            };
        }
    })),
    updatePlayer: player => set(produce(draft => {
        const index = draft.players.findIndex(t => t._id === player._id);

        if (index !== -1) {
            draft.players[index] = {
                ...draft.players[index],
                modified: new Date(),
                ...player
            };
        }
    })),
    setClients: clients => set(produce(draft => {
        draft.clients = clients;
    })),
    getTeamTricotColors: () => {
        const index = get().teams.findIndex(team => team._id === get().selectedTeam);

        if (index === -1) {
            return null;
        }

        let player = get().teams[index].prefs.player;

        return {
            name: 'custom',
            value1: player.color1,
            value2: player.color2,
            textColor: 'white.main',
        };
    },
    getSelectedTeam: () => {
        const index = get().teams.findIndex(teams => teams._id === get().selectedTeam);

        if (index === -1) {
            return null;
        }

        return get().teams[index];
    },
    getSelectedClient: () => {
        const index = get().clients.findIndex(clients => clients._id === get().selectedClient);

        if (index === -1) {
            return null;
        }

        return get().clients[index];
    },
})),
{
    name: 'settings', // unique name
    version: 1,
    partialize: (state) =>
        Object.fromEntries(
            Object.entries(state).filter(([key]) => !['clients', 'teams', 'players', 'active'].includes(key))
        ),
}
));
