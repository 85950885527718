import {useCallback} from 'react';

import {isOk} from 'fetcher/useFetcher';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';

import {useUserStore} from '../user';
import {useGetActiveUsersInLockerroom} from '../user/api';

export const useLoadActiveUsersInLockerroom = (roomId) => {
    const jwt = useUserStore(state => state.user?.jwt);
    const storeRoomId = useLockerRoomStore((state) => state.roomId);
    const setActiveUsers = useUserStore(state => state.setActiveUsers);

    const {load} = useGetActiveUsersInLockerroom(roomId ? roomId : storeRoomId);
    return useCallback(async () => {
        if (jwt) {
            let users = [];
            const res = await load();

            if (isOk(res)) {
                users = Array.isArray(res.data) ? res.data : [];
                setActiveUsers(users);
            }

            // console.log('active users', users);
        } else {
            setActiveUsers([]);
        }
    }, [jwt, load, setActiveUsers]);
};