import {useCallback} from 'react';

import {useFetcher} from 'fetcher';
import {isDevelopment,isOfflineDevelopment,isProdEnvironment,isTstEnvironment} from 'lib/env';
import {getEnv} from 'lib/env';

//testusers for lockerroom development
import {getLiveUser} from '../../../.dev/getLiveUser';
import {getTestUser} from '../../../.dev/getTestUser';

import _ from 'lodash';

const CONTROL_URL = getEnv('CONTROL_URL');

export const useGetActiveLockerrooms = () => {
    const {loading, fetch, data, hasError} = useFetcher(CONTROL_URL + 'user/lockerroom/active/', {
        method: 'GET',
    });

    const load = useCallback(async () => {
        return fetch();
    }, [fetch]);

    return {load, loading, lockerrooms: data, hasError};
};

export const useGetActiveUsersInLockerroom = (roomId) => {
    const {loading, fetch, data, hasError} = useFetcher(CONTROL_URL + `lockerroom/${roomId}/user/`, {
        method: 'GET',
    });

    const load = useCallback(async () => {
        return fetch();
    }, [fetch]);

    return {load, loading, activeUsers: data, hasError};
};

export const setupAppUserData = async (opening, active, roomId,setRoomId,setSelectedTeam, user, setUser) => {
    if (!window.dataApi || !typeof window.dataApi.getLockerroomParams === 'function') {
        return;
    }

    let params = await window.dataApi.getLockerroomParams();
    // console.log('DATAAPI: params', params);
                    
    if (!opening && !active && params?.data?.roomId !== roomId) {
        // console.log('useUser ROOMID SET: ', roomId, params.data.roomId, active, opening );

        //update roomid always when new roomid params are recieved
        // local server
        if (isOfflineDevelopment()) {
            setSelectedTeam('0815');
            setRoomId('1');
        }

        if (params?.data?.teamId) {
            setSelectedTeam(params.data.teamId);
                            
        }
                    
        if (params?.data?.roomId && params?.data?.roomId !== undefined) {
            setRoomId(params.data.roomId);
            // autojoin on new roomid params
        }
    }

    // if (active && params.data.roomId === roomId)
    //     showInfo('board.menu.message.player.already_in_lockerroom');

    if (!params.error && params?.data?.userId) {
        // user can join 1 room multiple times with different devices and same userId
        let newUser = {
            id: params.data.userId,
            appId: params.data.deviceInfo.app_id,
            firstname: params.data.firstname,
            lastname: params.data.lastname,
            jwt: params.data.jwt,
            roles: params.data.roles,
            image: params.data.image,
        };

        // only update user if it has changes
        if (!_.isEqual(user, newUser)) {
            console.log('get:user:', user);
            console.log('set: new app user:', newUser);
            setUser(newUser);
        }

    } else {
        if(params?.error){
            console.log('error getUser', params?.error);
            // params.error = {message: 'No valid device user found!'};
        }
    }

    return params;
};

export const setupBrowserUserData = async (opening, active, setSelectedTeam, user, setUser) => {
    if (!isDevelopment()) {
        return null;
    }

    let params = new URLSearchParams(window.location.search);

    let userId = params.get('userId');
    // console.log('useUser ROOMID SET: ', roomId, active, opening );

    if (!userId) {
        return null;
    }

    let adminId = params.get('adminId');
    let teamId = params.get('teamId');
    let devUser = null;
    // env cases

    // local server
    if (isOfflineDevelopment()) {
        devUser = getTestUser(userId);
        teamId = '0815';
    }

    // tst
    if (!isOfflineDevelopment() && isTstEnvironment()) {
        devUser = getTestUser(userId);
        if (!teamId) {
            teamId = '656f16e94e7321f658017c12'; // DEV-SCRIPT-USERS
        }
    }

    // live env
    if (!isOfflineDevelopment() && isProdEnvironment()) {
        devUser = getLiveUser(userId);
        if (!teamId) {
            teamId = '64e3497a748094bb4e029e52'; // GEOMIX Testteam
        }
    }

    // generic fallback
    if (devUser === null) {
        devUser = getTestUser(userId);
    }

    setSelectedTeam(teamId);

    let devUserWithRoles = {
        ...devUser,
        roles: userId === adminId ? [{team_id: teamId, functions: [{code: 'lockerrooms', permissions: ['create']}]}] : []
    };

    if (!_.isEqual(user, devUserWithRoles)) {
        console.log('set: new user:', devUserWithRoles);
        setUser(devUserWithRoles);
    }

    return devUserWithRoles;
};