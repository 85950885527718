// accepts usernumber 0-9 returns test db user object
// let userId = params.get('userId');

export function getTestUser(userId) {
    let myId = userId;
    let myJWT = '';
    let myFirstname = '';
    let myLastname = '';
    let myAppId = 'adminAppId01';
    let myImage = null;

    switch (userId) {
    case '0':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTc1MjRlNzMyMWY2NTgwMTdjMTUiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.heNPnyxi98wU0_hu7UCMSaD8NybOxkdQVKzObrubt8g';
        myId = '656f17524e7321f658017c15';
        myAppId =
            'OLMUVN3ZG4X34AXYU6GNCP2UMX6JNRWOVOHVCWP34YW36EIKHEQVUJI4QDJK7EISLLPJKGMCIFSB5HQZJPY7JRJDXHCMZ6U6QMQK7XQ';
        myFirstname = 'firstName_test1000';
        myLastname = 'firstName_test1000';
        break;
    case '1':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTZkOWI1NjAzYjlhZjcwMDM4NjEiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.W3BHV87et9Optc4WZ00l5mqcyaGOIh3k0jNK3co_kSs';
        myId = '656f16d9b5603b9af7003861';
        myAppId =
            '6OC2NKXGYCAEA7TDIBWEWMI4W7ZJWZR2C4KNZRF2YVVGYX7G5XXT6HN65XVM22GF624BNSVAESYRELY735U6RLWJDGANJ3RZAAIVYGQ';
        myFirstname = 'firstName_test1001';
        myLastname = 'firstName_test1001';
        break;
    case '2':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTZmZmI1NjAzYjlhZjcwMDM4NjIiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.UAobV3ykLBVMTSBzv1YqyN-2Vp8x9BGRYifesyyqMMU';
        myId = '656f16ffb5603b9af7003862';
        myAppId =
            'WYO5JEC5KTDLOAQYIOYPF6BYWSQMXX6WCKYP2RKVBHYN4VPZQEAWITZGN4SEYD5FYE5XNK2JIWHADC3T3NQQYFWRCUC3F6KIFCGONIQ';
        myFirstname = 'firstName_test1002';
        myLastname = 'firstName_test1002';
        break;
    case '3':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTcwZjYwMGI2ODdjZGYwZGU5MjAiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.D9yvoXcE4EN8lmP58cyOkUF2Hc93QH3mlQWgJgaa1Hc';
        myId = '656f170f600b687cdf0de920';
        myAppId =
            '4KKWJMOBYJCPPZU63E7YK46YIXQWOVGRZ4P2CNROBTDTXGWBEXVC5IKJHX5I4BUWYTCCAVUGKOPPJ55QDDHZAYV3RH7NNRB6YTSBT3Q';
        myFirstname = 'firstName_test1003';
        myLastname = 'firstName_test1003';
        break;
    case '4':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTcxYTRlNzMyMWY2NTgwMTdjMTMiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.BGT4kiUTBLv5Ukol5Q4AFopQK89YHaScAcN8I6pgwxs';
        myId = '656f171a4e7321f658017c13';
        myAppId =
            'JCX4XEA76552IHSWOISTA45JLPIKQX2BX3ZPFF7TK6IBKEG5NQQEKO5BV6R4EE2UWVAP2PH3ZSZI6QKCMLYEJ4RWFFRSBDD433ZUIRA';
        myFirstname = 'firstName_test1004';
        myLastname = 'firstName_test1004';
        break;
    case '5':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTcyOTRlNzMyMWY2NTgwMTdjMTQiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.IoajWdRZEjgyWXCJk2_o_yaXyYC1lSoiD-PdUsGHIuo';
        myId = '656f17294e7321f658017c14';
        myAppId =
            'MVH7FQINJD5OP4CJB6TVSU36T4T7WQTHWOY4CRNPY4CNCSRLXMV7XW7344V6KWTLQPRUP6FID4L3AGWDL6IOOZOQ3H77JLHZVMJ7KRI';
        myFirstname = 'firstName_test1005';
        myLastname = 'firstName_test1005';
        break;
    case '6':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTc4MGI1NjAzYjlhZjcwMDM4NjQiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.VCsL5ijbTb6W8loUZk27P-TcI0SX6nx6VMm_ZSjkhJY';
        myId = '656f1780b5603b9af7003864';
        myAppId =
            'AIFR4MFN5G5VOHOZQVWZ325BVIXMKGSNCJ44D2KZBBD4AY5D464HPQ4CH7MXFWJWSHCTXTFHK3QRJ3DIACVOABMPGRN5VGZDFPVAQSQ';
        myFirstname = 'firstName_test1006';
        myLastname = 'firstName_test1006';
        break;
    case '7':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTc5NDRlNzMyMWY2NTgwMTdjMTYiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.i_oo9Az1EPUur6LSikwWmRT2xUTtfo9SmqGuVVofnHY';
        myId = '656f17944e7321f658017c16';
        myAppId =
            'N7GW5I2QDASLTIUQVJF6BBUOG2CCNTWXG6H74LQ3MIBZBIBBOIXLJARISRDV6BOIX7EARU2FZ5CKA7NX6EVVQV5KKLAK5BZQFGLPFAA';
        myFirstname = 'firstName_test1007';
        myLastname = 'firstName_test1007';
        break;
    case '8':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTdhNTYwMGI2ODdjZGYwZGU5MjEiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.o1FKKSjMelc_P_S-izSZjktGZV7vy5pWcw6oJvMSj0M';
        myId = '656f17a5600b687cdf0de921';
        myAppId =
            'MHVNUKGDJ625UYXDIOUSTKA72CTOGTQNYCIVL5TCNDN5RPFLDKESUMBLP6L4OXZFC4DSJXDZGQHQT2OEWD2U2KHDVIUSYTGARZL7MUA';
        myFirstname = 'firstName_test1008';
        myLastname = 'firstName_test1008';
        break;
    case '9':
        myJWT =
            'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTdiOTRlNzMyMWY2NTgwMTdjMTciLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.Lwh4K6YaxZ_sR-c8ApANZoYecZOihzjTMuWJzvh-1Nk';
        myId = '656f17b94e7321f658017c17';
        myAppId =
            'IDIK3HAFE6KEDWIXCJ2YDLON6GT4JWD66ITBDXJXQ7BJS7KKJKRXS2G4V3J3ULPY4SRNEKS6VBE7NVTKKR5RM35I7UCGE3J5DGGWVGY';
        myFirstname = 'firstName_test1009';
        myLastname = 'firstName_test1009';
        break;
    case '10':
        // local-dev
        //myJWT = 'eyJhbGciOiJIUzI1NiIsImtpZCI6MiwidHlwIjoiSldUIn0.eyJkZXYiOiJIRDIzNDgyNzQtRERERCIsInN1YiI6IjY1NGI5Nzc0YjRiZDU3NzAzMTY2YWI0YyJ9.1-vKOvaQ-dfBw3ZkgKnZIQZHgKBcOokPF_ZTnFJtqc4';
        myJWT = 'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2M2ViOTc3NjZjOWVjNDk1ZTAwYjEwOTQiLCJkZXYiOiJkMDBjOTA0NTg3OWIwZGNhIn0.u-SG3USt9in0vp5S67ASr-ufAzqvYiybJOHBLnWZm_A';
        myId = '654b9774b4bd57703166ab4c';
        myAppId = 'foo';
        myFirstname = 'Karl';
        myLastname = 'Steinscheisser';
        break;

    default:
        break;
    }

    return {
        id: myId,
        appId: myAppId,
        jwt: myJWT,
        firstname: myFirstname,
        lastname: myLastname,
        image: myImage,
        roles: [
            // Roles need to be added afterwards
            // {
            //   team_id: teamId,
            //   functions: [{ code: 'lockerrooms', permissions: ['create'] }],
            // },
        ],
    };
}
