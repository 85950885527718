import React from 'react';

import {Close as CloseIcon} from '@mui/icons-material';
import {Alert, Button, Fade, Grow, IconButton, Slide, Snackbar as MuiSnackbar} from '@mui/material';

import {useTranslation} from 'hooks';
import {useSnackbarStore} from 'store';

// animation function
const TransitionSlideLeft = (props) => {
    return <Slide {...props} direction="left"/>;
};

const TransitionSlideUp = (props) => {
    return <Slide {...props} direction="up"/>;
};

const TransitionSlideRight = (props) => {
    return <Slide {...props} direction="right"/>;
};

const TransitionSlideDown = (props) => {
    return <Slide {...props} direction="down"/>;
};

const GrowTransition = (props) => {
    return <Grow {...props} />;
};

// animation options
const transitions = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade: Fade
};

const Snackbar = () => {
    const open = useSnackbarStore(state => state.open);
    const hide = useSnackbarStore(state => state.hide);
    const anchorOrigin = useSnackbarStore(state => state.anchorOrigin);
    const alertSeverity = useSnackbarStore(state => state.alertSeverity);
    const message = useSnackbarStore(state => state.message);
    const transition = useSnackbarStore(state => state.transition);
    const variant = useSnackbarStore(state => state.variant);
    const closeButton = useSnackbarStore(state => state.closeButton);

    const translate = useTranslation();

    const handleClose = (_event, _reason) => {
        hide();
    };

    return <>
        {/* default snackbar */}
        {variant === 'default' && (
            <MuiSnackbar
                TransitionComponent={transitions[transition]}
                anchorOrigin={anchorOrigin}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={translate(message)}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={handleClose}>
                            UNDO
                        </Button>
                        {closeButton !== false &&
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>}
                    </React.Fragment>
                }
            />
        )}

        {/* alert snackbar */}
        {variant === 'alert' && (
            <MuiSnackbar
                TransitionComponent={transitions[transition]}
                anchorOrigin={anchorOrigin}
                open={open}
                autoHideDuration={4000}
                ClickAwayListenerProps={{
                    mouseEvent: 'onMouseDown',
                    touchEvent: 'onTouchStart'
                }}
                onClose={handleClose}
            >
                <Alert
                    variant="filled"
                    severity={alertSeverity}
                    sx={{
                        bgcolor: alertSeverity + '.dark',
                        color: 'white.main'
                    }}
                    action={
                        <React.Fragment>
                            {closeButton !== false &&
                                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>}
                        </React.Fragment>
                    }
                >
                    {translate(message)}
                </Alert>
            </MuiSnackbar>
        )}
    </>;
};

export default Snackbar;
