import React from 'react';

import {
    Drawer as MuiDrawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    useTheme} from '@mui/material';

import Icons from 'assets/icons';
import {MenuHeader} from 'components';
import {useTranslation} from 'hooks';
import {isDevelopment} from 'lib/env';
import {hasPairingApi} from 'module/pairing/Api';
import {useUserStore} from 'module/user';
import {useLayoutStore} from 'store';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.light'
    },
    overlay: {
        padding: 0,
        width: '100%',
        zIndex: '990',
    },
    menuButton: {
        padding: '0',
        backgroundColor: 'black.light',
    },
    userButton: {
        borderBottom: '2px solid',
        borderColor: 'grey.light',
    },
    listIcon: {
        marginRight: '20px',
        width: 30,
        height: 30,
    },

};

const Drawer = () => {
    const translate = useTranslation();
    const theme = useTheme();

    const showDrawer = useLayoutStore((state) => state.showDrawer);
    const setShowDrawer = useLayoutStore((state) => state.setShowDrawer);
    const setShowSettings = useLayoutStore((state) => state.setShowSettings);
    const setShowProfile = useLayoutStore((state) => state.setShowProfile);
    // const setShowHelp = useLayoutStore((state) => state.setShowHelp);
    const setShowInformationDialog = useLayoutStore((state) => state.setShowInformationDialog);
    const setShowGroupMenu = useLayoutStore((state) => state.setShowGroupMenu);
    const setShowPairing = useLayoutStore((state) => state.setShowPairing);

    const user = useUserStore(state => state.user);

    const userInfo = user ? `${user?.firstname} ${user?.lastname}` : '';

    function handleClose() {
        setShowDrawer(false);
    }

    const iconStyle = {
        ...styles.listIcon,
        fill: theme.palette.grey.mainlight
    };

    return <MuiDrawer
        PaperProps={{sx: styles.drawer}}
        anchor={'left'}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
    >
        <List sx={styles.overlay}>
            <MenuHeader text={translate('board.menu.main')} handleClose={handleClose} />

            {/* Profil und Einstellungen */}
            <ListItem sx={[styles.menuButton, styles.userButton]}>
                <ListItemButton onClick={() => setShowProfile(true)}>
                    <ListItemText primary={translate('board.menu.settings')} secondary={userInfo}/>
                </ListItemButton>
            </ListItem>

            {/* Themen & Folien */}
            <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowGroupMenu(true, 'drawer')}>
                    <Icons.CopySlide style={iconStyle} />
                    <ListItemText primary={translate('board.menu.my_groups_sheets')} secondary={'Organisiere deine Tactix Themen & Folien'}/>
                </ListItemButton>
            </ListItem>

            {/* Teammanagment */}
            {!!window.dataApi && <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowSettings(true)}>
                    <Icons.Managment style={iconStyle} />
                    <ListItemText primary={translate('board.menu.my_clubs')} secondary={'Profil, Vereine, Teams und Mitglieder'}/>
                </ListItemButton>
            </ListItem>}

            {/* Kalender */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Calendar style={iconStyle} />*/}
            {/*        <ListItemText primary={'Kalender'} secondary={'Besprechungen & Trainings'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Digital Locker Room */}

            {/* <ListItem sx={styles.menuButton}>
                <ListItemButton>
                    <Icons.Lockerroom style={iconStyle} />
                    <ListItemText primary={'Digital Locker Room'} secondary={'Live Taktikbesprechungen'}/>
                </ListItemButton>
            </ListItem> */}

            {/* QR Code Scanner */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.QrCode style={iconStyle} />*/}
            {/*        <ListItemText primary={'QR Code Scanner'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Tutorials Videos */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Videos style={iconStyle} />*/}
            {/*        <ListItemText primary={translate('board.menu.tutorials')}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* FAQ */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Faq style={iconStyle} />*/}
            {/*        <ListItemText primary={'FAQ'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Follow us */}
            {/*<ListItem sx={styles.menuButton}>*/}
            {/*    <ListItemButton>*/}
            {/*        <Icons.Follow style={iconStyle} />*/}
            {/*        <ListItemText primary={'Follow us'}/>*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/* Kontakt */}
            <ListItem sx={styles.menuButton}>
                <ListItemButton onClick={() => setShowInformationDialog(true)}>
                    <Icons.Contact style={iconStyle} />
                    <ListItemText primary={translate('global.contact')}/>
                </ListItemButton>
            </ListItem>

            {/* Pairing */}
            {(hasPairingApi() || isDevelopment()) && <ListItem sx={styles.menuButton} key={'Pairing'}>
                <ListItemButton onClick={() => setShowPairing(true)}>
                    <Icons.Connect style={iconStyle} />
                    <ListItemText primary={translate('board.menu.pairing')}/>
                </ListItemButton>
            </ListItem>}
        </List>
    </MuiDrawer>;
};

export default Drawer;