import React from 'react';

import {
    Clear as CloseIcon,
} from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Button, ButtonBase,Stack, Typography} from '@mui/material';
import {Box} from '@mui/system';

const styles = {
    menuHeader: {
        color: 'white.main',
        height: '70px',
        padding: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'black.dark',
    },
    menuHeaderButton: {
        color: 'grey.mainlight',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 0 0 20px',
        boxSizing: 'borderBox',
        margin: 0,
        width: '70px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    menuHeaderText: {
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '70px',
        color: 'grey.mainlight',
    },
    menueClose: {
        color: 'red.dark',
    }
};

const MenuHeader = ({text, handleBackButton, handleClose}) => {

    return(
        <Stack sx={styles.menuHeader} direction="row" alignItems="center">

            {handleBackButton ?
                <ButtonBase sx={styles.menuHeaderButton} onClick={handleBackButton} disableRipple>
                    <KeyboardBackspaceIcon />
                </ButtonBase>
                : <Box ml={2}/>
            }
            
            <Typography sx={styles.menuHeaderText}>{text}</Typography>
            
            {handleClose && <Button sx={styles.menuHeaderButton} disableRipple onClick={handleClose}>
                <CloseIcon sx={styles.menueClose}/>
            </Button>}

        </Stack>
    );
};

export default MenuHeader;