import {fabric} from 'fabric';

export const PLAYER_INFO_OFFSET_TOP = 13;
export const CIRCLE_INFO_OFFSET_TOP = PLAYER_INFO_OFFSET_TOP;

export const sizeConverter = {
    'S': 1,
    'M': 2,
    'L': 3,
    'XL': 4
};

export const sizeOptions = {
    1: {
        label: 'S',
        player: {
            size: 70,
            nameTop: 47,
        },
        circle: {
            size: 20,
            nameTop: 42,
        }
    },
    2: {
        label: 'M',
        player: {
            size: 80,
            nameTop: 50,
        },
        circle: {
            size: 30,
            nameTop: 52,
        }
    },
    3: {
        label: 'L',
        player: {
            size: 90,
            nameTop: 55,
        },
        circle: {
            size: 40,
            nameTop: 78,
        }
    },
    4: {
        label: 'XL',
        player: {
            size: 110,
            nameTop: 63,
        },
        circle: {
            size: 50,
            nameTop: 82,
        }
    },
};

export const DEFAULT_SIZE = 2;

export const getNextPlayerNumber = (canvas, colors) => {
    // gets max player-number per team color
    const max = Math.max(...canvas.getObjects()
        .filter(e => e.custom && (e.custom.type === 'player' || e.custom.type === 'playerCircle')
            && e.custom.colors?.value1 === colors.value1 && e.custom.colors?.value2 === colors.value2)
        .flatMap(e => e.getObjects().filter(e => e.custom && e.custom.type === 'player-number')).map(e => e.text));

    return max > 0 ? max + 1 : 1;
};

// export const isNumberOnCanvas = (canvas, number) => {
//     return canvas.getObjects()
//         .filter(e =>
//             e.getObjects().filter(e => e.custom && e.custom.type === 'player-number' && e.text === number).length > 0
//         ).length > 0;
// };

export const isAnySettingsPlayerOnCanvas = (canvas) => {
    return canvas?.getObjects()?.filter(e => e.custom && e.custom?.playerId).length > 0;
};

export const isPlayerColorOnCanvas = (canvas, color) => {
    let searchColor = color.value1 ? color.value1 : color;
    return canvas?.getObjects()?.filter(e => e.custom && (e.custom?.colors?.value1 === searchColor || e.custom.colors === searchColor)).length > 0;
};

export const playerOnCanvas = (canvas, playerId) => {
    return canvas?.getObjects()?.filter(e => e.custom && e.custom.playerId && e.custom.playerId === playerId)[0];
};

export const isOppositeTeamSameColor = (canvas, colors, team) => {
    let searchColor = colors.value1 ? colors.value1 : colors;
    return canvas?.getObjects()?.filter(e => e.custom && (e.custom?.colors?.value1 === searchColor || e.custom.colors === searchColor) && e.custom.team !== team).length > 0;
};

export const isTeamWithSameDirection = (canvas, direction) => {
    return canvas?.getObjects()
        ?.filter(e => e.custom && e.custom.team === direction)
        .length > 0;
};

// same as isNumberOnCanvas but tests for same player color
export const playerNumberExists = (canvas, player, number) => {
    return canvas?.getObjects()
        ?.filter(e => e.custom && e.custom.colors?.value1 === player.custom.colors?.value1 && e.custom.colors?.value2 === player.custom.colors?.value2)
        .filter(e =>
            e.getObjects().filter(e => e.custom && e.custom.type === 'player-number' && e.text === number).length > 0
        ).length > 0;
};

export const getPlayerSizeOptions = (sizeValue = DEFAULT_SIZE) => {
    return sizeOptions[sizeValue].player;
};

export const getCircleSizeOptions = (sizeValue = DEFAULT_SIZE) => {
    return sizeOptions[sizeValue].circle;
};

export const createPlayerNumber = (number, textColor, size) => {
    return new fabric.Text(number, {
        custom: {
            type: 'player-number'
        },
        fontSize: size,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center',
        fill: textColor,
        fontFamily: 'Arial'
    });
};

const playerTextProps = (type) => {
    return {
        custom: {
            type
        },
        fontSize: 28,
        fontWeight: 'bold',
        originX: 'center',
        originY: 'center',
        fill: '#000000',
        fontFamily: 'Arial',
        textAlign: 'center',
    };
};

export const createPlayerName = (name, top) => {
    return new fabric.Text(name, {
        ...playerTextProps('player-name'),
        top,
        fontSize: 28
    });
};

export const createPlayerInfo = (info, top) => {
    return new fabric.Text(info, {
        ...playerTextProps('player-info'),
        top,
        fontSize: 20
    });
};

const findElementByType = (target, type, customType) => {
    return target.getObjects(type).find(o => {
        return o.custom && o.custom.type === customType;
    });
};

const getElementText = (target, customType) => {
    if (!target || !target.custom) return '';

    const elem = findElementByType(target, 'text', customType);

    if (!elem) {
        return '';
    }

    const txt = elem.get('text');

    // remove padding emulation, for existing texts with padding hack
    if (txt[0] === ' ') {
        txt.substring(1, txt.length - 2);
    }

    return txt;
};

export const getPlayerNameObj = (target) => {
    return findElementByType(target, 'text', 'player-name');
};

export const getPlayerInfoObj = (target) => {
    return findElementByType(target, 'text', 'player-info');
};

/**
 * @param target {fabric.Group}
 * @return {string}
 */
export const getPlayerName = (target) => {
    return getElementText(target, 'player-name');
};

/**
 * @param target {fabric.Group}
 * @param name {string}
 */
export const setPlayerName = (target, name) => {
    if (!target || !target.custom) return;
    const elem = findElementByType(target, 'text', 'player-name');
    const elemInfo = findElementByType(target, 'text', 'player-info');

    if (elem) {
        if (name.length === 0) {
            // do not remove, if player-info is present, causes fabric group problems
            if (elemInfo) {
                elem.set('text', '');
                target.canvas.renderAll();
                return;
            }

            target.remove(elem);
        } else {
            elem.set('text', name);
        }

    } else {
        if (name.length === 0) {
            return;
        }

        let sizeOptions = getPlayerSizeOptions(target.custom?.size);

        if(target.custom?.subType === 'playerCircle') {
            sizeOptions = getCircleSizeOptions(target.custom?.size);
        }

        let top = sizeOptions.nameTop;
        const elem = createPlayerName(name, top);
        target.add(elem);
    }

    target.addWithUpdate(); // @see https://stackoverflow.com/a/34933423
    if (target.canvas) target.canvas.renderAll();
};

/**
 * @param target {fabric.Group}
 * @return {string}
 */
export const getPlayerInfo = (target) => {
    return getElementText(target, 'player-info');
};

/**
 * @param target {fabric.Group}
 * @param info {string}
 */
export const setPlayerInfo = (target, info) => {
    if (!target || !target.custom) return;

    const elem = findElementByType(target, 'text', 'player-info');
    const elemName = findElementByType(target, 'text', 'player-name');

    if (elem) {
        if (info.length === 0) {
            target.remove(elem);

            if (elemName && !elemName.get('text')) {
                target.remove(elemName);
            }

        } else {
            elem.set('text', info);
        }

    } else {
        if (info.length === 0) {
            return;
        }

        // implicitely add player-name, fabric groups behavior is weird, when inserting elements
        if (!elemName) {
            setPlayerName(target, '');
        }

        let sizeOptions = getPlayerSizeOptions(target.custom?.size);
        let top = sizeOptions.nameTop + PLAYER_INFO_OFFSET_TOP;

        if(target.custom?.subType === 'playerCircle') {
            sizeOptions = getCircleSizeOptions(target.custom?.size);
            top = sizeOptions.nameTop + CIRCLE_INFO_OFFSET_TOP;
        }

        const elem = createPlayerInfo(info, top);
        target.add(elem);
    }

    target.addWithUpdate(); // @see https://stackoverflow.com/a/34933423
    target.canvas.renderAll();
};

/**
 * @param target {fabric.Group}
 * @return {string}
 */
export const getPlayerNumber = (target) => {
    if (!target || !target.custom) return '';

    const elem = findElementByType(target, 'text', 'player-number');

    return elem ? elem.get('text') : '';
};

/**
 * @param target {fabric.Group}
 * @param number {string}
 */
export const setPlayerNumber = (target, number) => {
    if (!target || !target.custom) return;

    const elem = findElementByType(target, 'text', 'player-number');

    if (elem) {
        elem.set('text', number);
        target.canvas.renderAll();
    }
};
