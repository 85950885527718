import React from 'react';

import {Stack, useTheme} from '@mui/material';

import icons from 'assets/icons';

import _ from 'lodash';

const svgIcons = {
    'more': icons.More,
    'back': icons.Back,
    'forward': icons.Forward,
    'new': icons.New,
    'save': icons.Save,
    'burger': icons.Burger
};

const styles = {
    root: {
        width: {
            mobile: '12.5%',
            desktop: '7.692307%',
        },
        height: {
            mobile: '50px',
            desktop: '60px',
        }
    },
    fullsizeButton: {
        width: '100%',
        height: {
            mobile: '50px',
            desktop: '60px',
        }
    },
    selected: {
        backgroundColor: 'black.light',
    }
};

const Button = ({color, icon, onClick, fullsize = false, selected = false}) => {
    const theme = useTheme();

    const SvgIcon = svgIcons[icon];
    
    return <Stack 
        alignItems="center"
        justifyContent="center"
        sx={{
            ...styles.root,
            ...(fullsize ? styles.fullsizeButton : {}),
            ...(selected ? styles.selected : {})
        }}
        onClick={onClick}
    >
        <SvgIcon style={{
            fill: color ? _.get(theme.palette, color) : theme.palette.white.main,

            display: 'block',
            width:  24,
            height: 24,
        }} />
    </Stack>;
};

export default Button;