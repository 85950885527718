import {create} from 'zustand';

import storage from 'store/localStorage';

import {produce} from 'immer';
import _ from 'lodash';
export const VIDEO_AUDIO = {audio: true, video: true};
export const AUDIO_ONLY = {audio: true, video: false};
export const VIDEO_ONLY = {audio: false, video: true};
export const MEDIA_OFF = {audio: false, video: false};
import {middleware} from 'store/zustand';

const useLockerRoomStore = create(middleware((set, get) => ({
    createdAt: 0,
    created: false, // true when creating a room has been finished
    connected: false,
    mediaRoomConnected: false, // true when media room has been connected
    routerRtpCapabilities: null, // true when media room has been connected
    title: '',
    opening: false,
    closing: false,
    closingForced: false,
    active: false, // true when room has been joined
    media: MEDIA_OFF,
    mediaDevices: {},
    ping: 0,
    roomId: null,
    editor: true, // false disables controls
    role: null,
    prevCanvasData: null,
    users: [],
    emitIntervalId: null,
    dataImage: null,
    reconnects: 0,
    invitedUsers: [],
    producing: false,
    roomTime: 0,
    loadingChangeEditor: false,
    setMediaRoomConnected: routerRtpCapabilities => set(produce(draft => {
        draft.routerRtpCapabilities = routerRtpCapabilities;
    })),
    setRouterRtpCapabilities: mediaRoomConnected => set(produce(draft => {
        draft.mediaRoomConnected = mediaRoomConnected;
    })),
    setDataImage: dataImage => set(produce(draft => {
        draft.dataImage = dataImage;
    })),
    setUsers: users => set(produce(draft => {
        if (!_.isEqual(draft.users, users)) {
            draft.users = users;
        }
    })),
    setTitle: title => set(produce(draft => {
        draft.title = title;
    })),
    setRoomId: roomId => set(produce(draft => {
        draft.roomId = roomId;
    })),
    setRole: role => set(produce(draft => {
        draft.role = role;
    })),
    setMedia: media => set(produce(draft => {
        draft.media = media;
    })),
    setMediaDevices: mediaDevices => set(produce(draft => {
        draft.mediaDevices = mediaDevices;
    })),
    setEditor: editor => set(produce(draft => {
        if (editor) draft.dataImage = null;
        draft.editor = editor;
    })),
    setPing: ping => set(produce(draft => {
        draft.ping = ping;
    })),
    incReconnects: () => set(produce(draft => {
        draft.reconnects = get().reconnects + 1;
    })),
    setActive: active => set(produce(draft => {
        draft.active = active;
    })),
    setOpening: opening => set(produce(draft => {
        draft.opening = opening;
    })),
    setClosing: closing => set(produce(draft => {
        draft.closing = closing;
    })),
    setClosingForced: closingForced => set(produce(draft => {
        draft.closingForced = closingForced;
    })),
    setConnected: connected => set(produce(draft => {
        draft.connected = connected;
    })),
    setCreated: created => set(produce(draft => {
        draft.created = created;
    })),
    setCreatedAt: createdAt => set(produce(draft => {
        draft.createdAt = createdAt;
    })),
    setInvitedUsers: invitedUsers => set(produce(draft => {
        draft.invitedUsers = invitedUsers;
    })),
    setProducing: producing => set(produce(draft => {
        draft.producing = producing;
    })),
    setRoomTime: roomTime => set(produce(draft => {
        draft.roomTime = roomTime;
    })),
    setLoadingChangeEditor: loadingChangeEditor => set(produce(draft => {
        draft.loadingChangeEditor = loadingChangeEditor;
    })),
    save: () => {
        storage.setItem(`lockerRoom_${get().roomId}`, get().users);
    },
}), {name: 'LockerRoomStore', debug: true}));

export {useLockerRoomStore};
