import lockerRoom from 'module/lockerRoom/socket';

// import {useInfoStore} from 'store';
import {useLockerRoomStore} from './zustand';

import * as mediasoupClient from 'mediasoup-client';

export const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
};
const EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen', // INFO: getDisplayMedia not supported by webview
    stopScreen: 'stopScreen'
};

let eventListeners = new Map();
Object.keys(EVENTS).forEach(evt => eventListeners.set(evt, []));

window.mediasoupClient = mediasoupClient;

let producer = null;
let startProduce = false;
/**
 * map that contains a mediatype as key and producerId as value
*/
let producerLabel = new Map();

let consumers = new Map();
let producers = new Map();

let producerTransport = null;
let consumerTransport = null;

let device = null;
let roomOpen = false;

const setMediaRoomConnected = useLockerRoomStore.getState().setMediaRoomConnected;
// const routerRtpCapabilities = useInfoStore.getState().routerRtpCapabilities;

const event = (evt) => {
    if (eventListeners.has(evt)) {
        eventListeners.get(evt).forEach((callback) => callback());
    }
};

export const loadDevice = async () => {
    const routerRtpCapabilities = await lockerRoom.request('getRouterRtpCapabilities');

    // loads the media device
    try {
        device = new mediasoupClient.Device();
        // console.log(device);
        // console.log(this.mediasoupClient.Device());
    } catch (error) {
        if (error.name === 'UnsupportedError') {
            console.error('User agent unknown');
            //alert('User agent unknown');
        }

        console.error(error);
    }

    if (routerRtpCapabilities) {
        await device.load({routerRtpCapabilities});
    }

    console.log('device', device);
    return device;
};
        
const initTransports = async () => {
    // init producerTransport
    {
        const data = await lockerRoom.request('createWebRtcTransport', {
            forceTcp: false,
            rtpCapabilities: device.rtpCapabilities
        });

        if (data.error) {
            console.error(data.error);
            return;
        }

        producerTransport = device.createSendTransport(data);

        producerTransport.on(
            'connect', async ({dtlsParameters}, callback, errback) =>
            {
                lockerRoom
                    .request('connectTransport', {
                        dtlsParameters,
                        transportId: data?.id
                    })
                    .then(callback)
                    .catch(errback);
            }
        );

        producerTransport.on(
            'produce', async ({kind, rtpParameters}, callback, errback) => {
                try {
                    const {producerId} = await lockerRoom.request('produce', {
                        producerTransportId: producerTransport?.id,
                        kind,
                        rtpParameters
                    });
                    callback({id: producerId});
                } catch (err) {
                    errback(err);
                }
            }
        );

        producerTransport.on(
            'connectionstatechange', async (state) => {
                console.log('MEDIA CONNECTIONSTATE CHANGE', state);

                switch (state) {
                case 'connecting':
                    break;

                case 'connected':
                    //localVideo.srcObject = stream
                    setMediaRoomConnected(true);
                    break;

                case 'failed':
                    // showInfo('producer connectionstatechange failed');
                    setMediaRoomConnected(false);
                    roomOpen = false;

                    break;

                default:
                    break;
                }
            }
        );
    }

    // init consumerTransport
    {
        const data = await lockerRoom.request('createWebRtcTransport', {
            forceTcp: false
        });

        if (data.error) {
            console.error(data.error);
            return;
        }

        // only one needed
        consumerTransport = device.createRecvTransport(data);
        consumerTransport.on(
            'connect', ({dtlsParameters}, callback, errback) => {
                lockerRoom
                    .request('connectTransport', {
                        transportId: consumerTransport?.id,
                        dtlsParameters
                    })
                    .then(callback)
                    .catch(errback);
            }
        );

        consumerTransport.on(
            'connectionstatechange', async (state) => {
                switch (state) {
                case 'connecting':
                    break;

                case 'connected':
                    //remoteVideo.srcObject = await stream;
                    //await socket.request('resume');
                    setMediaRoomConnected(true);
                    break;

                case 'failed':
                    // showInfo('consumer connectionstatechange failed');
                    setMediaRoomConnected(false);
                    roomOpen = false;
                    break;

                default:
                    break;
                }
            }
        );
    }
};

const initSockets = () => {
    lockerRoom.on('consumerClosed', (consumerId) => {
        console.log('Closing consumer:', consumerId);
        removeConsumer(consumerId);
    }
    );

    /**
 * data: [ {
 *  producerId:
 *  producer_socket_id:
 * }]
 */
    lockerRoom.on('newProducers', async (data) => {
        console.log('New producers', data);

        for (let {producerId} of data) {
            await consume(producerId);
        }}
    );

    // we do not leave room on disconnect -> we try to reconnect socket, mediasoup stays active all the time unless user acts to close room
    // lockerRoom.on('disconnect',() => exitRoom(true));
};

const getConsumeStream = async (producerId) => {
    const {rtpCapabilities} = device;
    const data = await lockerRoom.request('consume', {
        rtpCapabilities,
        consumerTransportId: consumerTransport?.id, // might be
        producerId
    });
    const {id, kind, rtpParameters} = data;

    let codecOptions = {};
    const consumer = await consumerTransport.consume({
        id,
        producerId,
        kind,
        rtpParameters,
        codecOptions
    });

    const stream = new MediaStream();
    stream.addTrack(consumer.track);

    return {
        consumer,
        stream,
        kind
    };
};

//MAIN EXPORTS

export const produce = async (type, deviceId = null, role, paused) => {
    if (startProduce) {
        // console.log('produce already started');
        return false;
    }

    startProduce = true;
    console.log('produce', type, deviceId);
    let mediaConstraints = {};
    let audio = false;
    let screen = false;

    switch (type) {
    case mediaType.audio:
        mediaConstraints = {
            audio: {
                deviceId: deviceId,
                autoGainControl: true,
                noiseSuppression: true,
                echoCancellation: true,
            },
            video: false
        };
        audio = true;
        break;
    case mediaType.video:
        mediaConstraints = {
            audio: false,
            video: {
                width: {
                    min: 640,
                    ideal: 1920
                },
                height: {
                    min: 400,
                    ideal: 1080
                },
                deviceId: deviceId
                /*aspectRatio: {
                        ideal: 1.7777777778
                    }*/
            }
        };
        break;
    case mediaType.screen:
        mediaConstraints = false;
        screen = true;
        break;
    default:
        return;
    }

    if (!device?.canProduce('video') && !audio) {
        console.error('Cannot produce video');
        startProduce = false;
        return;
    }

    if (producerLabel.has(type)) {
        console.log('Producer already exists for this type ' + type);
        startProduce = false;
        return;
    }

    console.log('Mediacontraints:', mediaConstraints);
    let stream;
    
    try {
        stream = screen
            ? await navigator.mediaDevices.getDisplayMedia()
            : await navigator.mediaDevices.getUserMedia(mediaConstraints);
        
        const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];
        const params = {
            track
        };

        if (!audio && !screen) {
            params.encodings = [
                {
                    rid: 'r0',
                    maxBitrate: 100000,
                    //scaleResolutionDownBy: 10.0,
                    scalabilityMode: 'S1T3'
                },
                {
                    rid: 'r1',
                    maxBitrate: 300000,
                    scalabilityMode: 'S1T3'
                },
                {
                    rid: 'r2',
                    maxBitrate: 900000,
                    scalabilityMode: 'S1T3'
                }
            ];
            params.codecOptions = {
                videoGoogleStartBitrate: 1000
            };
        }

        try {
            producer = await producerTransport.produce(params);

        } catch (error) {
            console.log('producerTransport produce error');
            return false;
        }

        console.log('Producer starting PAUSED', paused);

        if (role !== 'admin' && (paused === undefined || paused)){
            producer.pause();
            
            // pause - server
            lockerRoom.emit('producerPaused', {producerId: producer?.id});
        }

        producers.set(producer?.id, producer);
        
        let elem;

        // if (!audio) {
        //     elem = document.createElement('video');
        //     elem.srcObject = stream;
        //     elem?.id = producer?.id;
        //     elem.playsinline = false;
        //     elem.autoplay = true;
        //     elem.className = 'vid';
        //     // videoEl.appendChild(elem);
        //     handleFS(elem?.id);
        // }

        producer.on('trackended', () => {
            closeProducer(type);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close');

            if (!audio) {
                elem?.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
                elem?.parentNode.removeChild(elem);
            }

            producers.delete(producer?.id);
        });

        producer.on('close', () => {
            console.log('Closing producer');

            if (!audio) {
                elem?.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
                elem?.parentNode.removeChild(elem);
            }

            producers.delete(producer?.id);
        });

        producerLabel.set(type, producer?.id);

        switch (type) {
        case mediaType.audio:
            event(EVENTS.startAudio);
            break;
        case mediaType.video:
            event(EVENTS.startVideo);
            break;
        case mediaType.screen:
            event(EVENTS.startScreen);
            break;
        default:
            return;
        }

        return true;
    } catch (err) {
        console.log('Produce error:', err);
        return false;
    }
    finally {
        startProduce = false;
    }
};

export const consume = async (producerId) => {
//let info = await roomInfo()

    const {consumer, stream, kind} = await getConsumeStream(producerId);

    consumers.set(consumer?.id, consumer);

    let elem;

    if (kind === 'video') {
        // elem = document.createElement('video');
        // elem.srcObject = stream;
        // elem?.id = consumer?.id;
        // elem.playsinline = false;
        // elem.autoplay = true;
        // elem.className = 'vid';
        // // videoEl?.appendChild(elem);
        // handleFS(elem?.id);
    } else {
        elem = document.createElement('audio');
        elem.srcObject = stream;
        elem.id = consumer?.id;
        elem.playsinline = false;
        elem.autoplay = true;
        // audioEl?.appendChild(elem);

        consumerTransport.handler.getReceiverStats(consumer?.id).then((result) => {
            let report = result.lastWhere((report) => report.type == 'ssrc');
            console.log(report);
        }).catch ((err) => { console.log(err);});
    }

    consumer.on(
        'trackended', () => removeConsumer(consumer?.id)
    );

    consumer.on(
        'transportclose', () => removeConsumer(consumer?.id)
    );

};

export const closeProducer = (type) => {
    if (!producerLabel.has(type)) {
        return;
    }

    let producerId = producerLabel.get(type);
    console.log('Close producer', producerId);

    lockerRoom.emit('producerClosed', {
        producerId
    });

    producers.get(producerId)?.close();
    producers.delete(producerId);
    producerLabel.delete(type);

    if (type !== mediaType.audio) {
        let elem = document.getElementById(producerId);
        elem?.srcObject.getTracks().forEach((track) => {
            track.stop();
        });
        elem?.parentNode.removeChild(elem);
    }

    switch (type) {
    case mediaType.audio:
        event(EVENTS.stopAudio);
        break;
    case mediaType.video:
        event(EVENTS.stopVideo);
        break;
    case mediaType.screen:
        event(EVENTS.stopScreen);
        break;
    default:
        return;
    }
};

export const pauseProducer = (type) => {
    if (!producerLabel.has(type)) {
        console.log('There is no producer for this type ' + type);
        return;
    }

    let producerId = producerLabel.get(type);
    // pause - client
    producers.get(producerId).pause();
    // pause - server
    lockerRoom.emit('producerPaused', {producerId});
};

export const resumeProducer = (type) => {
    if (!producerLabel.has(type)) {
        console.log('There is no producer for this type ' + type);
        return;
    }

    let producerId = producerLabel.get(type);
    // pause - client
    producers.get(producerId).resume();
    // pause - server
    lockerRoom.emit('producerResumed', {producerId});
};

export const removeConsumer = (consumerId) => {
    let elem = document.getElementById(consumerId);
    elem?.srcObject.getTracks().forEach((track) => {
        track.stop();
    });
    elem?.parentNode.removeChild(elem);

    consumers.delete(consumerId);
};

export const isRoomOpen = () => {
    return roomOpen;
};

export const startRoom = async (user, role, roomId, mediaDevices, startPaused) => {
    
    if (role === 'admin') {
        await createRoom(roomId);
    }

    roomOpen = true;

    await prepareRoom(roomId, user.appId);
    await joinRoom(role, roomId, user.appId, mediaDevices, startPaused );
};

export const exitRoom = (offline = false, comeBack, role, roomId, appId, mediaDevices, producing) => {
    let clean = () => {
        roomOpen = false;

        producer = null;
        /**
         * map that contains a mediatype as key and producerId as value
        */
        producerLabel = new Map();

        consumers = new Map();
        producers = new Map();

        consumerTransport?.close();
        producerTransport?.close();

        device = null;
        lockerRoom.off('disconnect');
        lockerRoom.off('newProducers');
        lockerRoom.off('consumerClosed');
    };

    let comeBackMethod = async (startPaused) => {
        await prepareRoom(roomId, appId);
        await joinRoom(role, roomId, appId, mediaDevices, startPaused );
        setMediaRoomConnected(true);
    };

    if (!offline) {
        lockerRoom
            .request('exitRoom')
            .then((e) => { if (comeBack) comeBackMethod(!producing); console.log(e);})
            .catch((e) => console.warn(e))
            .finally(
                clean()
            );
    } else {
        clean();
    }

    event(EVENTS.exitRoom);
};

export const prepareRoom = async (roomId, appId) => {
    try {
        await lockerRoom.request('joinRoom', {appId, roomId});
        device = await loadDevice();
        console.log('prepared mediasoup room: ', roomId);
    } catch (error) {
        console.log('prepare room error', error, appId, roomId);
        roomOpen = false;
    }
};

export const joinRoom = async (role, roomId, appId, mediaDevices, startPaused) => {
    try {
        await initTransports();
        initSockets();
        lockerRoom.emit('getProducers');

        await produce(mediaType.audio, mediaDevices.audioDevice, role, startPaused);
        console.log('joined mediasoup room: ', roomId);
    } catch (error) {
        console.log('join room error', error, appId, roomId);
        roomOpen = false;
    }
};

export const createRoom = async (roomId) => {
    try {
        await lockerRoom.request('createRoom', {roomId});
        console.log('created Media Room: ', roomId);
        return true;
    } catch (error) {
        console.log('createRoom error');
        roomOpen = false;
        return false;
    }
};
