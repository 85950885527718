import React from 'react';

import {Close as CloseIcon} from '@mui/icons-material';
import {Backdrop, Box, Typography} from '@mui/material';
import {keyframes} from '@mui/system';

const rotate = keyframes`
  0% {transform: rotate(0turn);}
  100% {transform: rotate(1.0turn);}
`;

const jump = keyframes`

  0% { transform: translate3d(-10px, 0, 0); }
  49% { transform: translate3d(20px, -80px, 0); }
  50% { transform: translate3d(20px, -81px, 0); }
  53% { transform: translate3d(20px, -80px, 0); }
  95% { transform: translate3d(-20px, 25px, 0); }
  100% { transform: translate3d(-10px, 0, 0); }
`;

const styles = {
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'white.main',
        color: 'black.main',
        fontSize: '30px',
    },
    loadingBallJump: {
        animation: `${jump} 3s infinite linear`,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingTextSmall: {
        fontSize: '16px',
        textTransform: 'uppercase',
        lineHeight: 1,
        textAlign: 'center',
        zIndex: 2,
        backgroundColor: 'white.main',
        color: 'black.main',
        display: 'inline-block',
        padding: '10px 12px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    loadingBall: {
        animation: `${rotate} 1.5s infinite linear`,
    },
};

const LoadingOverlay = ({handleClose, text}) => {
    return <Backdrop
        sx={{backgroundColor: 'black.main', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open
    >
        <CloseIcon
            sx={styles.closeButton}
            onClick={() => handleClose()}
        />

        <Box>
            <Box sx={styles.loadingBallJump}>
                <Typography sx={styles.loadingTextSmall}>{text}</Typography>
                <Box sx={styles.loadingBall}>
                    <img src={'https://agentur.geomix.at/ball.png'} alt="Loading..."/>
                </Box>
            </Box>
        </Box>
    </Backdrop>;
};

export default LoadingOverlay;