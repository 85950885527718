import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 350,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile: 0,
            desktop: 900,
        },
    },
    palette: {
        primary: {
            main: '#00ffff',
        },
        secondary: {
            main: '#fff',
        },
        warning: {
            main: '#d50407',
        },
        error: {
            main: '#d50407',
        },
        success: {
            main: '#5eb202',
        },
        text: {
            primary: '#000',
            secondary: '#ccc',
            disabled: '#888',
        },
        info: {
            main: '#191919',
        },

        black: {
            dark: '#000000',
            main: '#0f0f0f',
            light: '#111111'
        },
        white: {
            main: '#f0f0f0',
            dark: '#e0e0e0',
            darker: '#d0d0d0',
            darkest: '#c0c0c0',
        },
        grey: {
            mainlight: '#aaaaaa',
            mainlight2: '#dddddd',
            darkest2: '#555555',
            darkest: '#222222',
            darker: '#3f3f3f',
            dark: '#4f4f4f',
            light: '#5f5f5f',
            lighter: '#6f6f6f',
            lightest: '#7f7f7f',
            lightester: '#8f8f8f',
            greyMainBackground: '#191919',
            greyMainBackgroundHover: '#212121',
        },
        green: {
            main: '#5eb202',
            lockerroom: '#408800',
            dark: '#29740f'
        },
        lockerroom: {
            background: '#f5f5f5',
            seperatorLines: '#e5e5e5',
            scrollbar: '#d5d5d5',
        },
        red: {
            main: '#f56b6b',
            dark: '#d50407',
            highlight: '#cc0033'
        },
        orange: {
            main: '#f1a700',
            warning: '#ec9807',
            dark: '#f16000'
        },
        yellow: {
            main: '#fdcf09',
            dark: '#f09e17'
        },
    },
    shape: {
        borderRadius: 2,
    },
    typography: {
        fontFamily: 'inter',
        fontSmooth: 'never',
        h1: {
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: '-0.24px',
        },
        h2: {
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '-0.24px',
        },
        h3: {
            fontWeight: 500,
            fontSize: 18,
            letterSpacing: '-0.24px',
            color: '#fff',
        },
        h5: {
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.24px',
        },
        h6: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '-0.24px',
        },
        subtitle1: {
            lineHeight: 1.4,
            fontSize: 16,
            color: '#fff',
        },
        body1: {
            color: '#fff',
        }
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '& .MuiListItemButton-root':{
                        padding: '16px',
                    }
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '18px',
                    color: '#dddddd',
                    whiteSpace: 'nowrap',
                },
                secondary: {
                    fontSize: '16px',
                    color: '#aaaaaa',
                    whiteSpace: 'nowrap',
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                container: {},
                paper: {
                    backgroundColor: '#0f0f0f',
                },
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#222222'
                },
                dividers: {
                    borderColor: '#ffffff',
                    borderBottom: 'none'
                },
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    '& .MuiLinearProgress-barColorPrimary': {
                        backgroundColor: '#2f2f2f',

                    },
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#fff',
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                },
                textColorSecondary: {
                    color: '#fff',
                },
            },
        },

        MuiAccordionSummary: {

            styleOverrides: {
                content: {
                    overflow: 'hidden',

                    root: {
                        '&.Mui-focusVisible': {
                            color: '#fff',
                        },
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                //disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '.MuiButton-endIcon': {
                        color: '#fff',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeLarge: {
                    fontSize: 24,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
                margin: 'normal',
                autoComplete: 'off'
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.MuiTextField-root': {
                        backgroundColor: '#222222'
                    }
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    zIndex: 1,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#aaaaaa',
                    borderRadius: '4px',
                    fontSize: 16,

                    '&.Mui-focused': {
                        color: '#aaaaaa',
                        borderColor: '#4e4e4e',
                    }
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    background: '#222222',
                    '&.MuiInput-root:before': {
                        color: '#aaaaaa',
                        borderBottom: '1px solid #4e4e4e'
                    },

                    '&.MuiInput-root:after': {
                        color: '#aaaaaa',
                        borderBottom: '1px solid #f5f5f5'
                    },
                },
                input: {
                    background: '#222222',
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicator: {
                    color: '#ffffff'
                },
                popper: {
                    backgroundColor: '#222222'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    /*backgroundColor: '#494949',*/
                },
                icon: {
                    color: '#fff',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#000',

                    '&.Mui-checked': {

                        color: '#5eb202',
                    }
                },
                colorSecondary: {
                    color: '#fff',
                }
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    background: '#292929',
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
                rounded: {
                    borderRadius: '6px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '.MuiMenu-list': {
                        color: '#fff'
                    }
                },
            },
        },
        MuiSlider: {

            styleOverrides: {

                valueLabel: {
                    fontSize: 18,
                    top: 29,
                    backgroundColor: 'unset',
                    color: '#000000',
                    '&:before': {
                        display: 'none',
                    },
                    '& *': {
                        background: 'transparent',
                        color: '#000000',
                        fontSize: 14,
                    }
                },
                mark: {
                    backgroundColor: '#aaaaaa',
                    height: '20px',
                    width: 2,
                },
                track: {
                    border: 'none',
                    height: '20px',
                    borderRadius: 0,
                },
                rail: {
                    opacity: 1,
                    backgroundColor: '#222222',
                    height: '20px',
                    border: 'none',
                    borderRadius: 0,
                },
                thumb: {
                    height: 28,
                    width: 28,
                    backgroundColor: '#fff',
                    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
                    '&:focus, &:hover, &.Mui-active': {
                        boxShadow:
                            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
                        },
                    },
                },
            },
        },
    },
});

export default theme;
