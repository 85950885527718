import React, {useState} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Drawer as MuiDrawer, Grid, List, Stack} from '@mui/material';

import {accordionStyles, CustomAccordion, CustomAccordionDetails, CustomAccordionSummary, MenuHeader, Text} from 'components';
import {useTranslation} from 'hooks';
import {useLayoutStore} from 'store';

import Language from './Language';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.light'
    },
    overlay: {
        padding: 0,
        width: '100%',
        zIndex: '999',
        animationName: 'slideToLeft',
        animationDuration: '0.1s',
        animationFillMode: 'forwards',
    },
};

const Profile = () => {
    const translate = useTranslation();

    const showProfile = useLayoutStore((state) => state.showProfile);
    const setShowProfile = useLayoutStore((state) => state.setShowProfile);

    const [expanded, setExpanded] = useState(false);

    const handleChange = async (panel, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function handleBackButton() {
        setShowProfile(false);
    }

    function handleForwardButton() {
        setShowProfile(false);
    }

    return <MuiDrawer
        PaperProps={{sx: styles.drawer}}
        anchor={'left'}
        open={showProfile}
        onClose={() => setShowProfile(false)}
    >
        <List sx={styles.overlay}>
            {/* Menü-Header */}
            <MenuHeader text={translate('global.settings')} handleBackButton={handleBackButton} handleForwardButton={handleForwardButton} />

            {/* Sprache */}
            <Grid key="language" item xs={12}>
                <CustomAccordion
                    expandedStyle={expanded === 'language'}
                    expanded={expanded === 'language'}
                    onChange={(_, isExpanded) => handleChange('language', isExpanded)}
                >
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon sx={accordionStyles.accordionExpandButton} />}
                    >
                        <Stack><Text>board.menu.language.language</Text></Stack>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Language />
                    </CustomAccordionDetails>
                </CustomAccordion>
            </Grid>

        </List>
    </MuiDrawer>;
};

export default Profile;
