import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {DEFAULT_SIZE} from 'canvas/Drawer/player-utils';
import {colors} from 'layout/components/toolbar';
import {middleware} from 'store/zustand';

import {produce} from 'immer';
import _ from 'lodash';

export const usePrefsStore = create(persist(middleware((set, _get) => (
    {
        tool: 'draw',
        prefs: {
            draw: {
                type: 'shape',
                shape: 'pencil',
                object: 'arrow'
            },
            player: {
                type: 'player',
                size: DEFAULT_SIZE
            }
        },
        color: colors[0],
        homeTeam: true,
        background: 'soccer',
        setTool: tool => set(produce((draft) => {
            draft.tool = tool;
        })),
        setPref: (tool, options) => set(produce((draft) => {
            if (!options) {
                return;
            }

            draft.prefs[tool] = _.merge(draft.prefs[tool], {
                ...options,
            });
        })),
        setBackground: (background) => set(produce((draft) => {
            draft.background = background;
        })),
        setColor: color => set(produce((draft) => {
            draft.color = color;
        })),
        setHomeTeam: (val) =>
            set(
                produce((draft) => {
                    draft.homeTeam = val;
                })
            ),
    }), {name: 'PrefsStore', debug: true}),
{
    name: 'prefs', // unique name
    version: 3, // NOTE: make sure migrations length should be version NO. + 1
    migrate: (persistedState, previousVersion) => {

        const migrations = [
            (state) => state, // version 0
            (state) => state, // version 1
            // version 2
            (state) => {
                delete state.tools;

                state.tool = 'select';
                state.color = colors[0];

                return state;
            },
            // version 3
            (state) => {
                for (const key of [
                    'selectedMenuItem', 'showClients', 'showDrawer', 'showHelp', 'showInformationDialog',
                    'showLanguage', 'showMenu', 'showPlayers', 'showSettings', 'showTeams', 'showTimeline']) {
                    delete state[key];
                }

                return state;
            },
        ];

        let state = persistedState;

        for (let i = previousVersion + 1; i < migrations.length; i++) {
            state = migrations[i](state);
        }

        return state;
    }
}));
