import Lineup343 from './3-4-3.svg?react';
import Lineup352 from './3-5-2.svg?react';
import Lineup4231 from './4-2-3-1.svg?react';
import Lineup4321 from './4-3-2-1.svg?react';
import Lineup433 from './4-3-3.svg?react';
import Lineup442 from './4-4-2.svg?react';
import Lineup442diamond from './4-4-2-diamond.svg?react';
import Lineup451 from './4-5-1.svg?react';
import Lineup451libero from './4-5-1-libero.svg?react';
import Lineup532 from './5-3-2.svg?react';
import Lineup541 from './5-4-1.svg?react';

// import {ReactComponent as Lineup325} from './3-2-5.svg';
// import {ReactComponent as Lineup4132} from '/4-1-3-2.svg';
// import {ReactComponent as Lineup4141} from '/4-1-4-1.svg';
// import {ReactComponent as Lineup4222} from '/4-2-2-2.svg';
// import {ReactComponent as Lineup523} from '/5-2-3.svg';

const defDefense4 = [{x: 1, y: 8.5}, {x: 6, y: 7}, {x: 12, y: 7}, {x: 17, y: 8.5}];
const defDefense5 = [{x: 0.5, y: 10.5}, {x: 4, y: 7.5}, {x: 9, y: 6.5}, {x: 14, y: 7.5}, {x: 17.5, y: 10.5}];
const defMidfield4 = [{x: 1.5, y: 16.5}, {x: 7, y: 14.5}, {x: 11, y: 14.5}, {x: 16.5, y: 16.5}];
const defAttack1 = [{x: 9, y: 23}];
const defAttack2 = [{x: 6.5, y: 23}, {x: 11.5, y: 23}];

const lineups = [
    {
        name: '3-4-3',
        Icon: Lineup343,
        positions: [
            {x: 3, y: 7.5}, {x: 9, y: 6.5}, {x: 15, y: 7.5},
            ...defMidfield4,
            {x: 3.5, y: 21.5}, {x: 9, y: 22.5}, {x: 14.5, y: 21.5},
        ]
    },
    {
        name: '3-5-2',
        Icon: Lineup352,
        positions: [
            {x: 3, y: 8}, {x: 9, y: 7}, {x: 15, y: 8},
            {x: 1, y: 16}, {x: 6.5, y: 13.5}, {x: 9, y: 17.5}, {x: 11.5, y: 13.5}, {x: 17, y: 16},
            ...defAttack2
        ]
    },
    {
        name: '4-2-3-1',
        Icon: Lineup4231,
        positions: [
            ...defDefense4,
            {x: 6.5, y: 12.5}, {x: 11.5, y: 12.5},
            {x: 3, y: 18.5}, {x: 9, y: 18.5}, {x: 15, y: 18.5},
            ...defAttack1
        ]
    },
    {
        name: '4-3-2-1',
        Icon: Lineup4321,
        positions: [
            ...defDefense4,
            {x: 4, y: 14}, {x: 9, y: 12}, {x: 14, y: 14},
            {x: 6, y: 20}, {x: 12, y: 20},
            {x: 9, y: 24}
        ]
    },
    {
        name: '4-3-3',
        Icon: Lineup433,
        positions: [
            ...defDefense4,
            {x: 5, y: 15}, {x: 9, y: 13}, {x: 13, y: 15},
            {x: 3, y: 21}, {x: 9, y: 23}, {x: 15, y: 21}
        ]
    },
    {
        name: '4-4-2',
        Icon: Lineup442,
        positions: [
            ...defDefense4,
            ...defMidfield4,
            ...defAttack2,
        ]
    },
    {
        name: '4-4-2 dia',
        Icon: Lineup442diamond,
        positions: [
            ...defDefense4,
            {x: 4, y: 16.5}, {x: 9, y: 13}, {x: 9, y: 20}, {x: 14, y: 16.5},
            ...defAttack2,
        ]
    },
    {
        name: '4-5-1',
        Icon: Lineup451,
        positions: [
            ...defDefense4,
            {x: 1, y: 16.5}, {x: 6, y: 15}, {x: 9, y: 18.5}, {x: 12, y: 15}, {x: 17, y: 16.5},
            ...defAttack1
        ]
    },
    {
        name: '4-5-1 libero',
        Icon: Lineup451libero,
        positions: [
            {x: 2, y: 7}, {x: 9, y: 9.5}, {x: 9, y: 5.5}, {x: 16, y: 7},
            {x: 1, y: 15}, {x: 6, y: 14}, {x: 9, y: 19}, {x: 12, y: 14}, {x: 17, y: 15},
            ...defAttack1
        ]
    },
    {
        name: '5-3-2',
        Icon: Lineup532,
        positions: [
            ...defDefense5,
            {x: 5, y: 17}, {x: 9, y: 14}, {x: 13, y: 17},
            ...defAttack2,
        ]
    },
    {
        name: '5-4-1',
        Icon: Lineup541,
        positions: [
            ...defDefense5,
            ...defMidfield4,
            ...defAttack1
        ]
    },
];

export default lineups;
