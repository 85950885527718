import React, {useRef} from 'react';

import {Check as CloseIcon} from '@mui/icons-material';
import {DialogContent} from '@mui/material';

import {canvasToJson} from 'canvas/canvas-helper';
import {useCanvasStore} from 'canvas/zustand';
import {Dialog} from 'components';
import {useTranslation} from 'hooks';

import Edit from './Edit';
import {usePlayerStore} from './zustand';

const PlayerDialog = () => {
    const translate = useTranslation();

    const player = usePlayerStore(state => state.player);
    const unsetPlayer = usePlayerStore(state => state.unset);
    const pushCanvas = useCanvasStore(state => state.pushCanvas);
    const canvas = useCanvasStore(state => state.canvas);
    const ref = useRef();

    const close = () => {
        ref.current?.submit();

        if (canvas) {
            pushCanvas(canvasToJson(canvas));
        }

        unsetPlayer();
    };

    return <Dialog
        open={!!player} handleClose={close} title={translate('board.player.player')}
        Icon={<CloseIcon/>}>
        <DialogContent dividers>
            {player && <Edit player={player} ref={ref}/>}
        </DialogContent>
    </Dialog>;
};

export default PlayerDialog;
