import * as React from 'react';
import {useState} from 'react';
import {useEffect, useRef} from 'react';

import BackHandIcon from '@mui/icons-material/BackHand';
import CloseIcon from '@mui/icons-material/Close';
import MicIcon from '@mui/icons-material/Mic';
import PeopleIcon from '@mui/icons-material/People';
import {Box} from '@mui/system';

import {ConfirmDialog, useConfirmDialog} from 'components';
import {useTranslation} from 'hooks';
import {useUserStore} from 'module/user';
import {useSnackbarStore} from 'store';

import {
    closeLockerroom,
    leaveLockerroom,
    toggleHand,
    toggleMicrophone,
    useChangeRoomEditor} from './api';
import ControlButton from './ControlButton';
import {useLockerRoomStore} from './zustand';

const styles = {
    toolbox: {
        overflow: 'visible',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        color: 'white',
        fontSize: '14px',
        flexWrap: 'wrap',
        width: '100%',
        padding: '8px 0 4px 0'
    },
  
    iconBtnBlack: {
        '@keyframes crescendo': {
            '0%' :  {transform: 'scale(1)'},
            '100%': {transform: 'scale(1.15)'}
        },
        backgroundColor: 'black.main',
        color: 'white.main',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '70%',
            top: '50%',
            left: '50%',
            right: 0,
            bottom: 0,
            height: '3px',
            backgroundColor: 'red.dark',
            transform: 'translate(-50%, -50%) rotate(-45deg)',
        }
    },

    iconBtnGrey: {
        backgroundColor: 'grey.light',
        color: 'grey.lightest',
    },

    iconBtnRed: {
        '&:active, :hover': {
            animation: 'crescendo 0.35s normal 1 ease-in',
        },
        backgroundColor: 'red.dark',
        color: 'white.main'
    },
    iconBtnGreen: {
        '&:active, :hover': {
            animation: 'crescendo 0.35s normal 1 ease-in',
        },
        backgroundColor: 'green.main',
        color: 'white.main'
    },
    iconBtnOrange: {
        '&:active, :hover': {
            animation: 'crescendo 0.35s normal 1 ease-in',
        },
        backgroundColor: 'orange.main',
        color: 'white.main'
    },
 
};

const Controls = ({openState, setOpen}) => {

    const showInfo = useSnackbarStore((state) => state.show);
    const translate = useTranslation();

    const closeLockerroomDialog = useConfirmDialog(
        () => {
            showInfo('lockerroom.message.next_time');
            setClosing(true);
            closeLockerroom(currentRoom, user);
        },
        translate('lockerroom.action.exit_lockerroom'),
        translate('lockerroom.action.confirm_exit'),
        translate('lockerroom.action.exit')
    );

    const users = useLockerRoomStore((state) => state.users);
    const user = useUserStore((store) => store.user);
    const adminUser = users.filter(user => Object.prototype.hasOwnProperty.call(user, 'admin')); 
    const ownUser = users?.find((u) => u.appId === user.appId);
    const editor = useLockerRoomStore((state) => state.editor);
    const role = useLockerRoomStore((state) => state.role);
    // const setActive = useLockerRoomStore((state) => state.setActive);
    const invitedUsers = useLockerRoomStore((state) => state.invitedUsers);
    const currentRoom = useUserStore((state) => state.currentRoom);
    const setClosing = useLockerRoomStore((state) => state.setClosing);
    const setProducing = useLockerRoomStore((state) => state.setProducing);

    const [micro, setMicro] = useState(ownUser?.status.microphone);
    const {changeEditor} = useChangeRoomEditor();

    const [hand, setHand] = useState(ownUser?.status.hand);
    const hands = users.some(user => user.status.hand === true) ? users.filter(user => user.status.hand === true).length : 0;
    const micUpdate = useRef(0);
    const handUpdate = useRef(0);
    useEffect(() => {
        //buttons update from server
        // for optimistic ui remove the state only after the server state matches local state
        // if 2 times not matching with server state then update from server
        if (micro !== ownUser?.status.microphone) {
            switch (micUpdate.current) {
            case 0:
                //count = 0, nothing happened, safe to update local from server
                setMicro(ownUser?.status.microphone);
                // pause the producer
                setProducing(ownUser?.status.microphone);
                break;
            case 1:
                //count = 1, user toggled one time update from server, don't change yet, might be old state
                micUpdate.current = 2;
                break;
            case 2:
                //count = 2, second time update from server, do the update and reset count 
                setMicro(ownUser?.status.microphone);
                micUpdate.current = 0;
                break;
            default:
                console.log('mic update error');
                break;
            }
        } else {
            // reset count if server and local state match
            micUpdate.current = 0;
        }

        if (hand !== ownUser?.status.hand) {
            switch (handUpdate.current) {
            case 0:
                //count = 0, nothing happened, safe to update local from server
                setHand(ownUser?.status.hand);

                break;
            case 1:
                //count = 1, user toggled one time update from server, don't change yet, might be old state
                handUpdate.current = 2;
                break;
            case 2:
                //count = 2, second time update from server, do the update and reset count 
                setHand(ownUser?.status.hand);
                handUpdate.current = 0;
                break;
        
            default:
                break;
            }
        } else {
            // reset count if server and local state match
            handUpdate.current = 0;
        }
  
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [ownUser]);

    return <Box sx={styles.toolbox}>

        {/* Leave Lockerroom */}
        <ControlButton
            openState={openState}
            onClickHandler={() => {
                if (role === 'admin') {
                    closeLockerroomDialog.show();
                }
                else {
                    setClosing(true);
                    if (editor)
                        changeEditor(currentRoom, adminUser);
                    leaveLockerroom(currentRoom, user);
                }
            }}
            icon={<CloseIcon fontSize="inherit" />}
            iconTextLine1={'lockerroom.action.leave'}
            styleBoxColor={styles.iconBtnRed}
        />

        {/* Microphone on/off */}
        <ControlButton
            openState={openState}
            onClickHandler={() => {
                setMicro(!micro);
                // pause the producer
                setProducing(!micro);
                toggleMicrophone(currentRoom, user);
                micUpdate.current = 1;
            }}
            icon={<MicIcon fontSize="inherit" />}
            iconTextLine1={'lockerroom.action.microphone'}
            iconTextLine2={micro ? 'lockerroom.action.on' : 'lockerroom.action.off'}
            styleBoxColor={micro ? styles.iconBtnGreen : styles.iconBtnBlack}
        />

        {/* ADMIN: Show Handsignals from Users */}
        {role === 'admin' &&
            (hands ?
                (
                    <ControlButton
                        pulse={true}
                        badge={hands}
                        badgeBgColor={'#fff'}
                        badgeTextColor={'orange.main'}
                        openState={openState}
                        onClickHandler={() => setOpen(!openState)}
                        icon={<BackHandIcon fontSize="inherit" />}
                        iconTextLine1={'lockerroom.action.hand_signal'}
                        iconTextLine2={hands}
                        styleBoxColor={styles.iconBtnOrange}
                    />
                ) : (
                    <ControlButton
                        pulse={false}
                        disabled={true}
                        openState={openState}
                        onClickHandler={() => setOpen(true)}
                        icon={<BackHandIcon fontSize="inherit" />}
                        iconTextLine1={'lockerroom.action.hand_signal'}
                        iconTextLine2={hands}
                        styleBoxColor={styles.iconBtnGrey}
                    />
                ))
        }

        {/* USER: Handsignal on/off */}
        {role !== 'admin' &&
            <ControlButton
                openState={openState}
                onClickHandler={() => {
                    setHand(!hand);
                    toggleHand(currentRoom, user);
                    handUpdate.current = 1;
                }}
                icon={<BackHandIcon fontSize="inherit" />}
                iconTextLine1={'lockerroom.action.hand_signal'}
                iconTextLine2={hand ? 'lockerroom.action.on' : 'lockerroom.action.off'}
                styleBoxColor={hand ? styles.iconBtnOrange : styles.iconBtnBlack}
            />
        }

        {/* Users in room / Toggle Userlist */}
        <ControlButton
            openState={openState}
            badge={users.length > 0 && users.length}
            badgeBgColor={'#fff'}
            badgeTextColor={'green.main'}
            onClickHandler={() => setOpen(!openState)}
            icon={<PeopleIcon fontSize="inherit" />}
            iconTextLine1={'lockerroom.member'}
            iconTextLine2={role === 'admin' ? `${users.length}/${invitedUsers.length+1 /* + admin */}` : ''}
            styleBoxColor={styles.iconBtnGreen}
        />

        <ConfirmDialog {...closeLockerroomDialog} />

    </Box>;
};

export default Controls;
