import React from 'react';

import {Link, Typography} from '@mui/material';

import {Dialog} from 'components';
import {isDevelopment} from 'lib/env';
import {Modes} from 'lib/tactix';

// import {useTranslation} from 'hooks';
import appleAppStore from '/src/assets/apple-app-store.png';
import googlePlayStore from '/src/assets/google-play-badge.png';

const styles = {
    dialogContentBlockTitle: {
        color: 'white.main',
        padding: '0 40px 5px 40px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '24px',
    },
    dialogContentBlock: {
        color: 'white.main',
        padding: '0 40px 20px 40px',
        textAlign: 'center',
    },
    dialogContentBlockButton: {
        color: 'white.main',
        padding: '0 0 10px 0',
        textAlign: 'center',
    },
    dialogContentBlockButtonHighlight: {
        color: 'white.main',
        padding: '0 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    buttonHolder: {
        padding: '20px 40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
    },
};

const navigateToStore = (storeUrl) => {
    const eventData = {url: storeUrl};
    window.ReactNativeWebView.postMessage(JSON.stringify({type: 'navigateToStoreEvent', data: eventData}));
};

const VersionCheck = ({mode}) => {
    let params = new URLSearchParams(window.location.search);
    console.log(mode, params.get('version'));
    // const translate = useTranslation();

    // TODO: integrate api calls for version checking
    // TODO: styling of dialog
    if (mode === Modes.APP && !params.get('appVersion') && !isDevelopment()) {
        return(
            <Dialog fullScreen Icon={<></>} open handleClose={() => null} title="App-Health">
                <Typography sx={styles.dialogContentBlockTitle}>Deine App-Version ist veraltet.</Typography>

                <Typography sx={styles.dialogContentBlock}>Bitte aktualisiere deine App, um alle Funktionen nutzen zu können.</Typography>

                <Link onClick={() => navigateToStore('https://apps.apple.com/at/app/geomix/id1572802456')} sx={styles.buttonHolder}>
                    <Typography sx={styles.dialogContentBlockButton}>Aktualisierung für Apple-Geräte <Typography sx={styles.dialogContentBlockButtonHighlight}>hier:</Typography></Typography>
                    <img src={appleAppStore} alt="" width="300" />
                </Link>

                <Link onClick={() => navigateToStore('https://play.google.com/store/apps/details?id=com.geomix.tactix')} sx={styles.buttonHolder}>
                    <Typography sx={styles.dialogContentBlockButton}>Aktualisierung für Android-Geräte <Typography sx={styles.dialogContentBlockButtonHighlight}>hier:</Typography></Typography>
                    <img src={googlePlayStore} alt="" width="300" />
                </Link>
            
            </Dialog>
        );

    }

    return null;
   
};

export default VersionCheck;