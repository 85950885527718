import React, {useEffect, useState} from 'react';

import {Grid, LinearProgress, Stack} from '@mui/material';

import {NoData} from 'components';
import {useTranslation} from 'hooks';
import ListItem from 'module/settings/team/ListItem';
import {useSettingsStore} from 'module/settings/zustand';

import TeamDialog from './TeamDialog';

const styles = {
    mainContainer: {
        width: '100%',
    },
    blockContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignSelf: 'flex-start',
        zIndex: 910,
    },
    progressStack: {
        backgroundColor: 'green.main',
    },
};

const Teams = ({loadingTeams}) => {
    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    const teams = useSettingsStore((store) => store.teams);
    const quicklink = useSettingsStore((store) => store.quicklink);
    const setQuicklink = useSettingsStore((store) => store.setQuicklink);
    const settingsQuicklink = useSettingsStore((store) => store.settingsQuicklink);
    const setSettingsQuicklink = useSettingsStore((store) => store.setSettingsQuicklink);

    const [expanded, setExpanded] = useState(false);

    const translate = useTranslation();

    const doSelect = (team) => {
        setExpanded(team._id);
    };

    useEffect(() => {
        if (selectedTeam && settingsQuicklink) {
            setExpanded(selectedTeam);
            setSettingsQuicklink(false);
        }
       
    }, [settingsQuicklink, selectedTeam, setSettingsQuicklink]);

    useEffect(() => {
        if (selectedTeam && quicklink) {
            setExpanded(false);
            setQuicklink(false);
        }
    }, [quicklink, selectedTeam, setQuicklink]);

    return (
        <Grid sx={styles.mainContainer}>
            {loadingTeams && <Stack>
                <LinearProgress sx={styles.progressStack} />
            </Stack>}
            {teams && !loadingTeams && teams.map(team => <Grid key={team._id} item xs={12} sx={styles.blockContainer}>
                <ListItem onSelect={() => doSelect(team)} team={team} selected={selectedTeam === team._id}/>
                {expanded === team._id && <TeamDialog
                    team={team}
                    onClose={() => setExpanded(false)}
                />}
            </Grid>)}
            {teams.length === 0 && !loadingTeams && <NoData text={translate('board.menu.message.player.no_team')}/>}
        </Grid>
    );
};

export default Teams;
