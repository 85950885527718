let isEnumerateDevices = false;
let audioDevice = null;
let videoDevice = null;

export const initMediaDevice = async (media) => {
    // Many browsers, without the consent of getUserMedia, cannot enumerate the devices.
    if (isEnumerateDevices) return {
        audioDevice,
        videoDevice
    };

    try {
        const stream = await navigator.mediaDevices.getUserMedia(media);
        console.log('MEDIASOUP: stream found', stream);

        // const used_devices = stream.getTracks()
        //     .map( (track) => track.getSettings().deviceId );
        // console.log('used_devices', used_devices);

        stream?.getTracks().forEach(function (track) {
            track.stop();
        });

        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log('MEDIASOUP: devices found', devices);

        isEnumerateDevices = true;

        // loop through all devices and choose...
        // audio: using 'default' device (// ONLY CHROME has default for audio!!!), TODO check for other platforms
        // video: using last device found with tag 'videoinput'
        devices.forEach((device) => {
            // console.log(device);
            console.log('device1',device.deviceId);

            if (device.kind === 'audioinput') {
                
                if (device.deviceId === 'default') {
                    audioDevice = device.deviceId;
                    console.log('default audio device', device);
                }

            } else if ('videoinput' === device.kind) {
                videoDevice = device.deviceId;
            }
        });

        return {
            audioDevice,
            videoDevice
        };

    } catch (err) {
        console.error('MEDIASOUP: Access denied for audio/video: ', err);
    }

    return null;
};
