// accepts usernumber 0-9 returns live db user object
// let userId = params.get('userId');

export function getLiveUser(userId) {
  let myId = userId;
  let myJWT = '';
  let myFirstname = '';
  let myLastname = '';
  let myAppId = 'adminAppId01';
  let myImage = null;

  switch (userId) {
    case '0':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NmE0MGY5MDFlZWJlZWQwYjcyMjIiLCJkZXYiOiI1NGQxMmMxYS0wMDAwLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.NJXXIOX3D03rkt9JVlG2FL795Uha8luHq7t4CE1QS2U';
      myId = '65666a40f901eebeed0b7222';
      myAppId = '54d12c1a-0000-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0000';
      myLastname = ' lastName_test0000';
      break;
    case '1':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NjYyOGIzMTExODlhNDUwNzk2ZjIiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.nPxE--xhAyfBOf2HiHETDMjmUXzOozOcnHMi-9Ts57M';
      myId = '65666628b311189a450796f2';
      myAppId = '54d12c1a-0001-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0001';
      myLastname = ' lastName_test0001';
      break;
    case '2':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NjZiMGVmNTViZTgxYWIwNGEwZTIiLCJkZXYiOiI1NGQxMmMxYS0wMDAyLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.TVcXaWDCfM2Dz7SAMWECXoEN3TUuTpAXaSEBnmVxMF0';
      myId = '656666b0ef55be81ab04a0e2';
      myAppId = '54d12c1a-0002-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0002';
      myLastname = ' lastName_test0002';
      break;
    case '3':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NjZlOWVmNTViZTgxYWIwNGEwZTMiLCJkZXYiOiI1NGQxMmMxYS0wMDAzLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.nSW2fALgnTry8OuvW8dSNlzFAfb2ZsdZ_zKqdUHZqXI';
      myId = '656666e9ef55be81ab04a0e3';
      myAppId = '54d12c1a-0003-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0003';
      myLastname = ' lastName_test0003';
      break;
    case '4':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NjZmZGVmNTViZTgxYWIwNGEwZTQiLCJkZXYiOiI1NGQxMmMxYS0wMDA0LTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.dXk9ysXqF_aNFOPdpDwTKL8kCqTqBtUr5vEpcIVRwlM';
      myId = '656666fdef55be81ab04a0e4';
      myAppId = '54d12c1a-0004-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0004';
      myLastname = ' lastName_test0004';
      break;
    case '5':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NjcxMmVmNTViZTgxYWIwNGEwZTUiLCJkZXYiOiI1NGQxMmMxYS0wMDA1LTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.-wsXW_N8f_6SWugPL1YqItKGtAv2wQmDRqf3r1qVh-A';
      myId = '65666712ef55be81ab04a0e5';
      myAppId = '54d12c1a-0005-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0005';
      myLastname = ' lastName_test0005';
      break;
    case '6':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NjcyYTlkY2Y5MzQ1MTEwOWVmMDIiLCJkZXYiOiI1NGQxMmMxYS0wMDA2LTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.rkor4qhfx16A46ZVQcxTuPEfsvXI1V2vf1aHE7HczO8';
      myId = '6566672a9dcf93451109ef02';
      myAppId = '54d12c1a-0006-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0006';
      myLastname = ' lastName_test0006';
      break;
    case '7':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NjczY2VmNTViZTgxYWIwNGEwZTYiLCJkZXYiOiI1NGQxMmMxYS0wMDA3LTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.gQG8p81k7_ZAuM_Zju_pQIwVUtmjXuq1eKRmMGXu4jw';
      myId = '6566673cef55be81ab04a0e6';
      myAppId = '54d12c1a-0007-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0007';
      myLastname = ' lastName_test0007';
      break;
    case '8':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NmEwYWJmMDAwNTIzZTgwMDQwYTIiLCJkZXYiOiI1NGQxMmMxYS0wMDA4LTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.QOrFBN8UDqnY782kHFydb85OaMkzzi6aWcpJl388nbc';
      myId = '65666a0abf000523e80040a2';
      myAppId = '54d12c1a-0008-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0008';
      myLastname = ' lastName_test0008';
      break;
    case '9':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTY2NmEyYWQzMDRmOTAxODUwMTgwZDIiLCJkZXYiOiI1NGQxMmMxYS0wMDA5LTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.JjocgjmBa3gVwQ8woDh9ykFOPOB7GQI9AwH7E37kgp4';
      myId = '65666a2ad304f901850180d2';
      myAppId = '54d12c1a-0009-11ed-846d-c739ba90d9c9';
      myFirstname = 'firstName_test0009';
      myLastname = ' lastName_test0009';
      break;
    default:
      break;
  }

  return {
    id: myId,
    appId: myAppId,
    jwt: myJWT,
    firstname: myFirstname,
    lastname: myLastname,
    image: myImage,
    roles: [
      // Roles need to be added afterwards
      // {
      //   team_id: teamId,
      //   functions: [{ code: 'lockerrooms', permissions: ['create'] }],
      // },
    ],
  };
}
