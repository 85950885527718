import React from 'react';

import {Stack} from '@mui/material';
import Radio from '@mui/material/Radio';

import {useLayoutStore} from 'store';

import {useActivateTeam} from '../useSettings';
import {useSettingsStore} from '../zustand';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '70px',
        marginBottom: '1px',
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '8px 0 8px 20px',
        backgroundColor: 'grey.darkest',
    },
    radioButtonContainer: {
        width: '70px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioButton: {
        color: 'white.main',
        '&.Mui-checked': {
            color: 'green.main',
        },
    }
};

const ListItem = ({team, onSelect, selected}) => {
    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    const activateTeam = useActivateTeam();
    const setShowSettings = useLayoutStore((state) => state.setShowSettings);

    const doSelect = (showSettings, closeMenu) => {

        // no team -> select new team
        if(selectedTeam !== team._id){
            activateTeam(team);        
        }

        // on click on selected -> select no team
        if(selectedTeam === team._id && !showSettings) {
            setSelectedTeam(null);
        }

        // click on selected to show settings
        if (selectedTeam === team._id && showSettings) {
            onSelect(team);
        }

        // close menu on selection when radio button is clicked
        if (closeMenu) {
            setTimeout(function() {
                setShowSettings(false);
            }, 400);
        }
    };

    return <Stack sx={styles.root} onClick={() => doSelect(selectedTeam === team._id, false)}>
        <Stack>{team.name}</Stack>
        <Stack
            sx={styles.radioButtonContainer}
            onClick={(e) => {
                e.stopPropagation();
                doSelect(false, selectedTeam !== team._id);
            }}
        >
            <Radio
                checked={selected}
                value={team._id}
                name="teams"
                sx={styles.radioButton}
            />
        </Stack>
    </Stack>;
};

export default ListItem;
