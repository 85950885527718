import React from 'react';

import {Close as CloseIcon} from '@mui/icons-material';
import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';

import {Text} from 'components';

const styles = {
    root: {
        '.MuiDialog-container': {
            alignItems: 'baseline',
        },
        margin: 0,
        width: '100%',
    },
    dialogHeader: {
        backgroundColor: 'grey.darkest',
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dialogTitle: {
        color: 'white.main',
        fontSize: '18px',
        padding: '20px',
    },
    closeIconButton: {
        margin: 0,
        padding: '20px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },    
    content: {
        padding: 0,
    }
};

const BaseDialog = ({open, handleClose, title, children, Icon, pt= 20, ...rest}) => {
    const sx = styles.root;
    if (pt) {
        sx['& .MuiDialog-container'] = {pt};
    }

    return <Dialog 
        open={open} onClose={handleClose} fullWidth={true} sx={sx} {...rest}>
        {title && <DialogTitle sx={styles.dialogHeader}>
            <Typography sx={styles.dialogTitle}><Text>{title}</Text></Typography>
            {handleClose && <IconButton sx={styles.closeIconButton} data-testid="dialog-close-btn" onClick={handleClose} color="secondary">
                {Icon || <CloseIcon />}
            </IconButton>}
        </DialogTitle>}

        <DialogContent sx={styles.content}>
            {children}
        </DialogContent>
    </Dialog>;
};

export default BaseDialog;
