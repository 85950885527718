import React, {useEffect} from 'react';

import {useTranslation} from 'hooks';
import {isOfflineDevelopment} from 'lib/env';
import {useSettingsStore} from 'module/settings/zustand';
import {useUserStore} from 'module/user';

import {closeLockerRoomConnection, connectionHandler} from './api';
//import {useTranslation} from 'hooks';
import ControlsDrawer from './ControlsDrawer';
import {LockerroomToolbar} from './index';
import LoadingOverlay from './LoadingOverlay';
import LockerroomHooks from './LockerroomHooks';
import {useLockerRoomStore} from './zustand';

const LockerRoom = () => {
    
    const translate = useTranslation();
    const created = useLockerRoomStore((state) => state.created);
    const connected = useLockerRoomStore((state) => state.connected);
    const active = useLockerRoomStore((state) => state.active);
    const opening = useLockerRoomStore((state) => state.opening);
    const setOpening = useLockerRoomStore((state) => state.setOpening);
    const closing = useLockerRoomStore((state) => state.closing);
    const setClosing = useLockerRoomStore((state) => state.setClosing);
    const role = useLockerRoomStore((state) => state.role);
    const setConnected = useLockerRoomStore((state) => state.setConnected);
    const roomId = useLockerRoomStore((state) => state.roomId);
    const setRoomId = useLockerRoomStore((state) => state.setRoomId);
    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    // const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    const user = useUserStore((state) => state.user);
    // const mode = useInfoStore(state => state.mode);
   
    useEffect(() => {
        //development only
        if (isOfflineDevelopment() && !roomId) {
        // if development mode is active and mode === browser
            const params = new URLSearchParams(window.location.search);

            if (params.get('roomId') && params.get('roomId') !== undefined) {
                setRoomId(params.get('roomId'));
            // setLockerrooms([{id: '1', room_id: params.get('roomId')}]);
            } 
        }

        // for autojoin
        if (!isOfflineDevelopment() && roomId && !opening && !active && !connected && !closing) {
            console.log('autojoin roomId', roomId);
            setOpening(true); 
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId]);

    useEffect(() => {
        console.log('LOCKERROOM STATECHANGE',connected ? 'connected' : 'NOTconnected',created ?'created' : 'NOTcreated', opening ?'opening' : 'NOTopening', active ?'active' : 'NOTactive' );

        // for autojoin and normal connections
        if (roomId && !connected && opening && !active) {
            connectionHandler({selectedTeam, user, roomId});
        }

        // for connection loss and rejoin
        if (roomId && !connected && !opening && active) {
            connectionHandler({selectedTeam, user, roomId});
        }

        if (!created && !opening && !active && connected) {
            closeLockerRoomConnection(roomId);
            setConnected(false);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId, connected, created, opening, active]);

    // console.log('opening', opening ,'active', active);
    return <>
        {!active && opening && <LoadingOverlay
            handleClose={() => {
                console.log('CANCEL ROOM OPENING?');
                setOpening(false);
            }}
            text={translate('lockerroom.message.starting')}
        />}
        {active && closing && <LoadingOverlay
            handleClose={()=> {
                console.log('CANCEL ROOM CLOSING?');
                setClosing(false);

            }}
            text={role==='admin' ? translate('lockerroom.message.closing') : translate('lockerroom.message.leaving')}
        />}
        {active ?
            // show lockeroom controls und Userlist
            <ControlsDrawer/>
            : // icon and startmenu to launch lockerroom
            <LockerroomToolbar/>}

        {(created || active || opening || closing) && <LockerroomHooks />}
    </>;
};

export default LockerRoom;
