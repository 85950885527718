import React, {forwardRef} from 'react';

import {Slide} from '@mui/material';

import {Dialog} from 'components';

import {Sheet} from './index';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const SheetDialog = ({open, onClose, sheet, onSave}) => {
    return <Dialog
        maxWidth={false}
        pt={0}
        open={open}
        TransitionComponent={Transition}
        onClose={onClose}
    >
        <Sheet sheet={sheet} onSave={onSave} onCancel={onClose}/>
    </Dialog>;
};

export default SheetDialog;