import {useCallback, useState} from 'react';

import {useApplyChangesToToolbar} from 'module/settings/utils';
import {useSnackbarStore} from 'store';

import {getRemoteClients, getRemotePlayers, getRemoteTeams, updateRemoteTeam} from './api';
import {useSettingsStore} from './zustand';

export const useLoadClients = () => {
    const [loading, setLoading] = useState(false);
    const showInfo = useSnackbarStore((state) => state.show);
    const language = useSettingsStore((store) => store.selectedLanguage);

    const setClients = useSettingsStore((store) => store.setClients);

    const load = useCallback(async () => {
        setLoading(true);
        const res = await getRemoteClients(language);
        setLoading(false);

        if (!res) {
            return [];
        }

        if (res.error) {
            showInfo(res.error.message, {severity: 'error'});

            return [];
        }

        setClients(res.data);

        return res.data;
    }, [language, setClients, showInfo]);

    return {
        loadClients: load,
        loadingClients: loading,
    };
};

export const useLoadTeams = () => {
    const [loading, setLoading] = useState(false);
    const showInfo = useSnackbarStore((state) => state.show);
    const language = useSettingsStore((store) => store.selectedLanguage);

    const setTeams = useSettingsStore((store) => store.setTeams);

    const load = useCallback(async (clientId) => {
        setLoading(true);
        const res1 = await getRemoteClients(language);

        // always check clients first before gettings remote teams
        const res = res1?.data?.find(el => el._id === clientId) ? await getRemoteTeams(clientId, language) : null;

        setLoading(false);

        if (!res) {
            return [];
        }

        if (res.error) {
            showInfo(res.error.message, {severity: 'error'});

            return [];
        }

        setTeams(res.data);

        return res.data;
    }, [language, setTeams, showInfo]);

    return {
        loadTeams: load,
        loadingTeams: loading,
    };
};

export const useLoadPlayers = () => {
    const showInfo = useSnackbarStore((state) => state.show);
    const language = useSettingsStore((store) => store.selectedLanguage);

    const setPlayers = useSettingsStore((store) => store.setPlayers);

    return useCallback(async (teamId) => {
        const res = await getRemotePlayers(teamId, language);

        if (!res) {
            return [];
        }

        if (res.error) {
            showInfo(res.error.message, {severity: 'error'});

            return [];
        }

        setPlayers(res.data);

        return res.data;
    }, [language, setPlayers, showInfo]);
};

export const useUpdateRemoteTeam = () => {
    const showInfo = useSnackbarStore((state) => state.show);
    const selectedClient = useSettingsStore((store) => store.selectedClient);
    const language = useSettingsStore((store) => store.selectedLanguage);

    return useCallback(async (team) => {
        team.client = selectedClient;
        const res = await updateRemoteTeam(team, language);

        if (res.error) {
            console.log('failed', res.error);
            showInfo(res.error.message, {severity: 'error'});
        } else {
            showInfo('team.action.save_success');
        }

        return res;
    }, [selectedClient, language, showInfo]);
};

export const useActivateTeam = () => {
    const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    const setSelectedClient = useSettingsStore((store) => store.setSelectedClient);

    const applyChangesToToolbar = useApplyChangesToToolbar();

    const loadPlayers = useLoadPlayers();

    return useCallback((team) => {
        setSelectedTeam(team._id);
        setSelectedClient(team.client);
        loadPlayers(team._id);
        applyChangesToToolbar(team);
    }, [applyChangesToToolbar, loadPlayers, setSelectedClient, setSelectedTeam]);
};
