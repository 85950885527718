import React, {useEffect, useState} from 'react';

import {registerDefaultHeader, registerResponseEffect} from './fetcher/useFetcher';
import {Modes} from './lib/tactix';
import {useGroupLoad} from './module/group/useGroup';
import {useSettingsStore} from './module/settings/zustand';
import {useSheetLoad} from './module/sheet/useSheet';
import {useUserStore} from './module/user';
import storage from './store/localStorage';
import {MigrationDialog, useMigration2to3, useMigration3to4, useMigration4to5, useMigrationToServer} from './migration';
import {useLayoutStore} from './store';
import {useInit} from './useInit.js';

export const Bootstrap = ({mode, children}) => {
    const [block, setBlock] = useState(false);

    const lang = useSettingsStore(state => state.selectedLanguage);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const {loading: loading1, load: loadSheets} = useSheetLoad(true);
    const {loading: loading2, load: loadGroups} = useGroupLoad(true);

    const setUser = useUserStore(state => state.setUser);
    const user = useUserStore(state => state.user);
    const jwt = user?.jwt;

    const migrate2to3 = useMigration2to3();
    const migrate3to4 = useMigration3to4();
    const migrate4to5 = useMigration4to5();
    const migrateToServer = useMigrationToServer(mode);

    const {initData} = useInit();

    console.log('bootstrap', block, loading1);

    // log user out, if 403 was returned from server
    registerResponseEffect(403, () => {
        setUser(null);
    });

    useEffect(() => {
        // register default lang header, this avoids extra dependencies for callback api functions, which
        // should not regenerate if language changes, they should just use the current language set
        registerDefaultHeader('Accept-Language', lang);
    }, [lang]);

    useEffect(() => {
        console.log('set jwt', jwt);

        if (jwt) {
            registerDefaultHeader('Authorization', `Bearer ${jwt}`);
        } else {
            registerDefaultHeader('Authorization', undefined);
        }

        // migration must run if jwt changes only, i.e. user logs in/out
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt]);

    useEffect(() => {
        //disable legacy migration because migration group causes duplicate key errors
        //migrate1to2();
        migrate2to3();
        migrate3to4();
        migrate4to5();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // load data either from local stores or from backend
    // fetch user
    useEffect(() => {
        const doInit = async () => {
            const local = !jwt;

            console.log('bootstrap init', local);

            // ev. migrate data to server
            if (jwt && mode !== Modes.MONITOR && !storage.getItem('migrated')) {
                setBlock(true);
                await migrateToServer();
                setBlock(false);
            }

            try {
                const sheets = await loadSheets(local);
                const groups = await loadGroups(local);

                await initData(groups, sheets);
            } catch (e) {
                console.log(e.cause);
            }
        };

        doInit();

        // getUser depends on roomId, do not add it as dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initData, loadGroups, loadSheets, jwt, mode]);

    useEffect(() => {
        setShowLoading(loading1 || loading2);
    }, [loading1, loading2, setShowLoading]);

    // do not render anything conditionally after startup, this causes the canvas handle to be changed
    // which causes errors
    return <>
        {block && <MigrationDialog mode={mode}/>}
        {children}
    </>;
};
