import React from 'react';

import {Box, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';

import icons from 'assets/icons';
import {Text} from 'components';
import {usePrefsStore} from 'store';

const styles = {
    toolButton: {
        width: {
            mobile: '24%',
            desktop: '11.5%',
        },
        margin: '0.5%',
        backgroundColor: 'grey.darkest',
        aspectRatio: '1/1',
    },
    selected: {
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'white.main',
    },
    titleHolder: {
        marginLeft: '0.5%',
    },
};

const SvgIcons = {
    'pencil': icons.Pencil,
    'arrow': icons.Arrow,
    'triangle': icons.Triangle,
    'arrowDashed': icons.ArrowDashed,
    'circle': icons.Circle,
    'rect': icons.Rectangle,
    'futbol': icons.SoccerBall,
    'cone': icons.ConeLarge,
    'coneSmall': icons.ConeSmall,
    'pole': icons.Pole,
    'ladder': icons.Ladder,
    'text': icons.Text,
    'down': icons.ArrowDownward,
    'up': icons.ArrowUpward,
};

const shapes = [
    'pencil', 'arrow', 'triangle', 'arrowDashed', 'circle', 'rect'
];

const objects = [
    'futbol', 'cone', 'coneSmall', 'pole', 'ladder'
];

const Button = ({name, selected, onClick}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
    const SvgIcon = SvgIcons[name];

    return <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={[styles.toolButton, selected && styles.selected]}
        onClick={onClick}>
        <SvgIcon style={{
            fill: '#ffffff',
            display: 'block',
            width: isMobile ? 40 : 60,
            height: isMobile ? 40 : 60,
        }}/>
    </Box>;
};

const DrawingTool = ({onSelect}) => {
    const prefs = usePrefsStore((state) => state.prefs.draw);
    const setPref = usePrefsStore((state) => state.setPref);

    const onClick = (type, name) => {
        setPref('draw', {type, [type]: name});
        onSelect('draw');
    };

    return <>
        <Box>
            <Typography sx={styles.titleHolder} variant="h1" color="grey.mainlight" mb={3}><Text>board.toolbar.draw</Text></Typography>
            <Typography sx={styles.titleHolder} variant="h2" color="grey.mainlight" mb={1}><Text>board.toolbar.shapes</Text></Typography>
        </Box>
        <Stack direction="row" flexWrap="wrap" useFlexGap={true}>
            {shapes.map((name, index) => <Button
                key={index}
                name={name}
                selected={prefs?.type === 'shape' && prefs?.shape === name}
                onClick={() => onClick('shape', name)}/>)}
        </Stack>
        <Box mt={4}>
            <Typography sx={styles.titleHolder} variant="h2" color="grey.mainlight" mb={1}><Text>board.toolbar.objects</Text></Typography>
        </Box>
        <Stack direction="row" flexWrap="wrap" useFlexGap={true}>
            {objects.map((name, index) => <Button
                key={index}
                name={name}
                selected={prefs?.type === 'object' && prefs?.['object'] === name}
                onClick={() => onClick('object', name)}/>)}
        </Stack>
    </>;
};

export default DrawingTool;