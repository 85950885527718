import {useCallback, useEffect, useRef} from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

import {useMode} from 'canvas';
import {getModifiedCounter, incModifiedCounter} from 'canvas/canvas-helper';
import {useCanvasLoad} from 'canvas/useCanvas';
import {useCanvasStore} from 'canvas/zustand';
import {fabric} from 'fabric';
import {useInterval} from 'hooks';
import lockerRoom from 'module/lockerRoom/socket';
import {useLoadActiveLockerrooms} from 'module/lockerRoom/useLoadActiveLockerrooms';
import {updateRemoteLockerroomState} from 'module/settings/api';
import {useSettingsStore} from 'module/settings/zustand';
import {useSheet} from 'module/sheet/useSheet';
import {useUserStore} from 'module/user';
import {useSnackbarStore} from 'store';
import {usePrefsStore} from 'store';

import {createLockerroom, joinLockerroom,pingServer} from './api';
import {AUDIO_ONLY, MEDIA_OFF, useLockerRoomStore} from './zustand';

export const sendImage = (canvas, active, editor) => {
    // console.log('sendImage ' , active, editor);

    if (!active || !editor) {
        return;
    }

    // const started = Date.now();
    const gfx = canvas.toDataURL({
        format: 'jpg',
        quality: 0.4
    });
    
    if (lockerRoom.allowSendImage) {
        console.log('emitChanges -> socketIO ' + gfx.length);
        lockerRoom.allowSendImage = false;
        lockerRoom?.volatile.emit('imageUpdate', gfx, (response) => {
            lockerRoom.allowSendImage = true;
            console.log('imageUpdate response', response.status);
        });
    }
};

export const useLockerroom = () => {
    const loadActiveLockerrooms = useLoadActiveLockerrooms();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const prevData = useRef(0);

    const setRole = useLockerRoomStore((state) => state.setRole);
    const role = useLockerRoomStore((state) => state.role);
    const setMedia = useLockerRoomStore((state) => state.setMedia);

    const canvas = useCanvasStore(state => state.canvas);
    const setCanvasMode = useMode();
    const {load: loadCanvas} = useCanvasLoad();

    const setTool = usePrefsStore(state => state.setTool);

    const setOpening = useLockerRoomStore((state) => state.setOpening);
    const setClosing = useLockerRoomStore((state) => state.setClosing);

    const active = useLockerRoomStore((state) => state.active);
    const setPing = useLockerRoomStore((state) => state.setPing);
    const setActive = useLockerRoomStore((state) => state.setActive);
    
    const setDataImage = useLockerRoomStore((state) => state.setDataImage);
    // const roomId = useLockerRoomStore((state) => state.roomId);
    const setRoomId = useLockerRoomStore((state) => state.setRoomId);
    const setCreatedAt = useLockerRoomStore((state) => state.setCreatedAt);
    const setProducing = useLockerRoomStore((state) => state.setProducing);
    const setLoadingChangeEditor = useLockerRoomStore((state) => state.setLoadingChangeEditor);

    const user = useUserStore((state) => state.user);
    
    const setCurrentRoom = useUserStore((state) => state.setCurrentRoom);
    // const setLockerrooms = useUserStore((state) => state.setLockerrooms);
    const editor = useLockerRoomStore((state) => state.editor);
    const setEditor = useLockerRoomStore((state) => state.setEditor);
    // const connected = useLockerRoomStore((state) => state.connected);
    const setConnected = useLockerRoomStore((state) => state.setConnected);
    const setCreated = useLockerRoomStore((state) => state.setCreated);
    const reconnects = useLockerRoomStore((state) => state.reconnects);
    const incReconnects = useLockerRoomStore((state) => state.incReconnects);
    const setRoomTime = useLockerRoomStore((state) => state.setRoomTime);
    const setUsers = useLockerRoomStore((state) => state.setUsers);
    const users = useLockerRoomStore((state) => state.users);
    // const mediaDevices = useLockerRoomStore((state) => state.mediaDevices);
    // const mediaRoomConnected = useLockerRoomStore((state) => state.mediaRoomConnected);
    const setMediaRoomConnected = useLockerRoomStore((state) => state.setMediaRoomConnected);
    // const producing = useLockerRoomStore((state) => state.producing);

    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    // const currentRoom = useUserStore((state) => state.currentRoom);

    const showInfo = useSnackbarStore(state => state.show);

    const background = usePrefsStore((state) => state.background);
    const setBackground = usePrefsStore((state) => state.setBackground);

    const {frameIndex, selectedSheet: sheet} = useSheet();
    const editable= frameIndex === 0;

    const isEditor = useRef(editor);

    const backgroundRef = useRef(background);

    // console.log(`active: ${active} editor: ${editor}`);

    const clearLockerRoom = () => {
        setRoomId(null);
        clearImageTimer();
        updateRemoteLockerroomState(false);
        setMedia(MEDIA_OFF);
        setProducing(false);
        setEditor(true); // sets dataimage null
        setActive(false);
        setCreated(false);
        setMediaRoomConnected(false);

        if (role !== 'admin' && sheet) {
            loadCanvas(sheet, isMobile);
        }

        setRole(null);
        loadActiveLockerrooms();
        setClosing(false);

    };

    // const runSendImage = () => {
    //     if (prevData.current == getModifiedCounter(canvas)) {
    //         return;
    //     }

    //     console.log('image useInterval', prevData.current, getModifiedCounter(canvas), background, isEditor.current);
    //     prevData.current = getModifiedCounter(canvas);

    //     sendImage(canvas, active, isEditor.current);

    useInterval(useCallback(() => {
        pingServer((pingValue) => {
            setPing(pingValue);
            // console.log('pingServer', pingValue);
        });
    }, [setPing]), 1000);

    const [clearImageTimer, startImageTimer] = useInterval(useCallback(() => {
        if (prevData.current == getModifiedCounter(canvas)) {
            return;
        }

        console.log('image useInterval', prevData.current, getModifiedCounter(canvas));
        prevData.current = getModifiedCounter(canvas);

        sendImage(canvas, active, editor);
    }, [canvas, active, editor]), 200);

    useEffect(() => {
        
        if (editor && isEditor.current !== editor) {
            isEditor.current = editor;
            incModifiedCounter(canvas);
            startImageTimer();
        } else {
            isEditor.current = false;
            clearImageTimer();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, startImageTimer, clearImageTimer]);

    // useEffect(() => {
    //     if (connected && active && !mediaRoomConnected) {
    //         showInfo('important EXITROOM');
    //         console.log('exitRoom TRIGGERED',connected, active, mediaRoomConnected);
    //         // please checkout this method how it works
    //         exitRoom(false, true, role, roomId, user.appId, mediaDevices, producing);
    //     }
    //     // INFO: Do not add more dependencies
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [connected, mediaRoomConnected]);

    useEffect(() => {
        updateRemoteLockerroomState(active);
    }, [active]);

    useEffect(() => {
        console.log('update counter for backgroundchange', background);
        backgroundRef.current = background;
        // postpone a bit, rendering the new background takes some time
        incModifiedCounter(canvas);
       
    }, [background, canvas]);

    useEffect(() => {

        console.log('LOCKEROOM EFFECT');

        lockerRoom.on('connect', async () => {
            incReconnects();
            console.log(`reconnected: ${reconnects+1} `);
            if (reconnects > 0) showInfo(`Verbindung wurde wiederhergestellt`);
            console.log('SOCKET IO connected');
            console.log('SOCKET', lockerRoom.actionOnConnect);

            if (lockerRoom.actionOnConnect === 'join' || !lockerRoom.actionOnConnect) {
                joinLockerroom(lockerRoom.roomId, user);
                lockerRoom.actionOnConnect = '';
            }
            
            if (lockerRoom.actionOnConnect === 'create') {
                createLockerroom(selectedTeam, user, lockerRoom.title, lockerRoom.roomId, lockerRoom.invitedUsers);
                lockerRoom.actionOnConnect = '';
            }

            setConnected(true);
            
        });
    
        lockerRoom.on('created', (data) => {
            console.log('Lockerroom created, ', data);
            setActive(true);
            setOpening(false);
            setCreated(true);
            setCurrentRoom(data);
            setRole('admin');
            setMedia(AUDIO_ONLY);
            setCreatedAt(data.createdAt || 0);
            incModifiedCounter(canvas);
            startImageTimer();
            console.log('created', data.createdAt);
        });
    
        lockerRoom.on('exitRoomDone', () => {
        });
    
        lockerRoom.on('adminToEdit', () => {
            console.log('admin to edit');
            setEditor(true);
            setLoadingChangeEditor(false);
        });
    
        lockerRoom.on('createError', () => {
            setOpening(false);
            showInfo('lockerroom.message.cannot_start', {severity: 'error'});
        });
    
        lockerRoom.on('joined', (data) => {
            console.log('Lockerroom joined, ', user);
            setEditor(false);
            setActive(true);
            setOpening(false);
            setCurrentRoom(data);
            setRole('user');
            setTool('select');
            setCanvasMode(editable && isEditor.current);
            setMedia(AUDIO_ONLY); // TODO could get this setting from creator
            setProducing(false); // join with microfone muted
            setCreatedAt(data.createdAt || 0);
            // dataImage transfer active now, so remove standard canvas/bg 
            setBackground(null); // clear background so it can be updated on editorchange

            console.log('joined', data.createdAt);
        });
    
        lockerRoom.on('adminJoined', (data) => {
            console.log('Lockerroom joined by admin, ', user);
    
            if (data.user.appId === user.appId) {
                setRole('admin');
                setActive(true);
                setCurrentRoom(data);
                setMedia(AUDIO_ONLY);
                setProducing(true);
                incModifiedCounter(canvas);
                startImageTimer();

            } else {
                showInfo('lockerroom.message.admin_joined');
            }
    
        });
    
        lockerRoom.on('userHand', (data) => {
            if (role === 'admin') {
                showInfo('lockerroom.message.become_editor', data);
            }
        });
    
        lockerRoom.on('joinError', (data) => {
            setOpening(false);
            setClosing(false);
            showInfo('lockerroom.message.unavailable', {severity: 'error'});
            console.log('JOINERROR',data);
        });
    
        lockerRoom.on('editorUnlock', () => {
            console.log('editorUnlock');
            setLoadingChangeEditor(false);
        });
    
        // this event is triggered from client(admin) -> server/loseEditing
        // purpose: need to transfer canvas from current editor to new editor before editor changes
        lockerRoom.on('loseEditing', (roomId, userData) => {
            console.log('loseEditing', userData);
            setEditor(false);
            const updateData = {canvasObject: canvas, background: backgroundRef.current};
            lockerRoom?.compress(true).emit('changeRoomEditor', JSON.stringify(updateData), roomId, userData);
        });
    
        lockerRoom.on('left', (data) => {
            console.log('lockerrooms rcv left', data);
            clearLockerRoom({reason: 'left'});
        });
    
        lockerRoom.on('ping', (data, time, callback) => {
            callback();
            // let ownUser = data?.find((u) => u.appId === user.appId);
            // console.log('ping', ownUser.status?.ping);
           
            if (data) {
                // copy users volumes to data.users.status volume
                users.forEach((user) => {
                    let index = data.users.findIndex((dataUser) => user.appId === dataUser.appId);

                    if (index > -1) {
                        data.users[index].status.volume = user.status.volume;
                    }
                });

                data.volumes.forEach((volumeData) => {
                    let index = data.users.findIndex((user) => user.appId === volumeData.appId);

                    if (index > -1) {
                        // low volume
                        if (volumeData.volume >= 0 && volumeData.volume <= 2) {
                            data.users[index].status.volume = 0;
                            return;
                        }

                        // mid volume
                        if (volumeData.volume > 2 && volumeData.volume < 6) {
                            data.users[index].status.volume = 1;
                            return;
                        }

                        // max volume
                        if (volumeData.volume >= 6) {
                            data.users[index].status.volume = 2;
                            return;
                        }

                        // no volume data found, but user is there, so turn volume off (-1)
                        data.users[index].status.volume = -1;
                    } 

                });

                // console.log('update users', data);
                setUsers(data.users);
            }

            if (time) setRoomTime(time);
        });
    
        lockerRoom.on('disconnect', (reason, details) => {
            // showInfo('disconnected, trying reconnect, please wait', {severity: 'warning'});
            // TODO reconnect cases for reasons
            console.log('LOCKERROOM DISCONNECTED');
            console.log(reason);
            console.log(details?.context?.status); // 400
            console.log(details?.context?.responseText); // '{"code":1,"message":"Session ID unknown"}'
            // now cleanup room -> close room
            // leaveLockerroom(currentRoom, user);
            // console.log('....SOCKET IO re-connecting');
            // lockerRoom.connect();
        });
    
        lockerRoom.on('closed', async (data) => {
            console.log('lockerrooms rcv closed', data);
            clearLockerRoom({reason: 'closed'});
        });
    
        lockerRoom.on('terminated', async (data) => {
            showInfo('lockerroom.message.admin_inactive', {severity: 'warning'});
            console.log('lockerrooms rcv terminated', data);
            clearLockerRoom({reason: 'terminated'});
        });
    
        // listen to users update on server
        lockerRoom.on('users', (newUsers) => {
            console.log('all users - IS THIS USED ANYMORE?');
            setOpening(false);

            if (!newUsers) {
                console.log('LOCKERRROOM no new users set active to false, no cleanup?');
                setActive(false);
                return;
            }
    
            setUsers(newUsers);
        });
    
        lockerRoom.on('userImage', (data) => {
            // console.log(data.length);
            console.log('userImage', data.length);

            if (data) {
                // console.log('image update');
                // setBackground(null);
                // canvas.clear();
                setDataImage(data);
            }
        });
    
        lockerRoom.on('newProducers', (data) => {
            console.log('data', data);
            setOpening(false);
        });
    
        lockerRoom.on('userCanvas', (data, editorChange, roomId) => {
            console.log('userCanvas');
    
            try {
                if (canvas && data.length) {
                    const jsonData = JSON.parse(data);
    
                    if (jsonData.canvasObject) {
                        console.log('canvas update');
                        setDataImage(null);
                        if (canvas.getObjects()) canvas.remove(...canvas.getObjects());
                        fabric.util.enlivenObjects(jsonData.canvasObject.objects, (objs) => {
                            // canvas._objects.push.apply(canvas._objects, objs);
                            objs.forEach((item) => {
                                canvas.add(item);
                            });
                        });
                    }
    
                    if (jsonData.background) {
                        console.log('background update',jsonData.background);
                        setBackground(jsonData.background);
                    }
                    
                    if (jsonData.canvasObject || jsonData.background) {
                        canvas.renderAll();
                        incModifiedCounter(canvas);
                    }
                }
            } catch (error) {
                console.log(error);
                // TODO reset editor state to prevent user crash on disconnecting client connection 
            }
            finally {
                if (editorChange) {
                    console.log('editor change complete', roomId);
                    lockerRoom?.emit('editorChangeComplete', roomId);
                    setEditor(true);
    
                }
            }
    
        });

        return () => {
            console.log('lockerroom unmounted');
            lockerRoom.removeAllListeners();
            setEditor(true); // to get back to normal editor state if lockerroom is left
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

};