import React, {useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import {CssBaseline, ThemeProvider} from '@mui/material';
import {Box} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';

import {Snackbar} from 'components';
import {isProduction} from 'lib/env';
import {getMode} from 'lib/tactix';
import {useSettingsStore} from 'module/settings/zustand';
import {useUserStore} from 'module/user';

import {version} from '../package.json';

//import loadDemoData from './assets/demodata';
import Text from './components/Text';
import {useUser} from './module/user/useUser';
import {Bootstrap} from './Bootstrap';
import {Layout} from './layout';
import Periodic from './Periodic';
import {useInfoStore} from './store';
import VersionCheck from './VersionCheck';

import './App.css';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

import FontFaceObserver from 'fontfaceobserver';
import LogRocket from 'logrocket';
import {Settings} from 'luxon';
import theme from 'theme/Theme';

const styles = {
    loadingInfoContainer: {
        backgroundColor: '#000',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        fontSize: 22,
        fontWeight: 400,
        color: '#fff',
        textAlign: 'center',
    }
};

const ErrorFallback = ({error, resetErrorBoundary}) => {
    window?.logApi?.error(error.message);
    window?.logApi?.error(error.stack);
    console.log(error.message);
    console.log(error.stack);
    return (
        <div role="alert" className="fatal-error">
            <p className="error-title">Ein Problem ist aufgetreten:</p>
            <h1>Oops, Foul, Elfmeter, Rote Karte ...</h1>
            <div>
                <a href="" title="Tactix neu laden" onClick={resetErrorBoundary}>
                    Tactix neu laden
                </a>
                <div>{error.message}</div>
                <div>{error.stack}</div>
            </div>
        </div>
    );
};

//loadDemoData(2);

const App = () => {
    const user = useUserStore((state) => state.user);
    const setUser = useUserStore((state) => state.setUser);
    const title = useInfoStore(state => state.title);
    const deviceId = useInfoStore(state => state.deviceId);

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [loading, setLoading] = useState(true);

    Settings.defaultLocale = useSettingsStore(state => state.selectedLanguage);
    const mode = useInfoStore(state => state.mode);
    const setMode = useInfoStore(state => state.setMode);

    const getUser = useUser();

    console.log(`mode: ${mode} version: ${version}`);

    // everything which must be loaded first, i.e. is vital
    useEffect(() => {
        const doLoad = async () => {
            try {
                const fontLoader = new FontFaceObserver('inter');
                await fontLoader.load();
            } catch (_) {
                console.log(`inter font can't be loaded, so you may see different font on canvas.`);
            }

            setMode(await getMode());
            const usr = await getUser();

            if (user) {
                setUser(usr);
            }

            setLoading(false);
        };

        doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isProduction()) {
            return;
        }

        // if (mode === Modes.MONITOR) {
        LogRocket.init('lixnls/tactix');
        // }
    }, [mode]);

    useEffect(() => {
        if (!isProduction() || !user?.id) {
            return;
        }

        LogRocket?.identify(user?.id);
    }, [user]);

    useEffect(() => {
        console.log('device', title, deviceId);

        if (!isProduction() || !title || !deviceId) {
            return;
        }

        LogRocket?.identify(String(deviceId), {
            name: title
        });
    }, [title, deviceId]);

    useEffect(() => {
        function updateOnlineStatus() {
            setIsOnline(navigator.onLine);
        }

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    // we wait until the font was loaded so the canvas can render the correct font
    if (loading) {
        return null;
    }

    if (!isOnline) {
        return <Box sx={styles.loadingInfoContainer}><Text>board.layout.is_offline</Text></Box>;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => location.reload()}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'de'}>
                <ThemeProvider theme={theme}>
                    <Bootstrap mode={mode}>
                        <VersionCheck mode={mode}/>
                        <Periodic/>
                        <CssBaseline/>
                        <Snackbar/>
                        <Layout/>
                    </Bootstrap>
                </ThemeProvider>
            </LocalizationProvider>
        </ErrorBoundary>
    );
};

export default App;
