import React, {useCallback, useEffect} from 'react';

import {
    Box,
    DialogActions,
    DialogContent, Typography,
} from '@mui/material';

import {Dialog as BaseDialog, Text} from 'components';
import {isOk} from 'fetcher/useFetcher';
import {useInterval, useTranslation} from 'hooks';
import {isDevelopment} from 'lib/env';
import {useUserStore} from 'module/user';
import {useInfoStore} from 'store';

import {getTestUser} from '../../../.dev/getTestUser';

import {getPairing} from './Api';

import ReactQRCode from 'qrcode.react';

const styles = {
    dialogContainer: {
        align: 'center'
    },
    qrcodeContainer: {
        backgroundColor: '#fff', // keep this, otherwise phones have problem scanning the qrcode
        display: 'inline-flex',
        padding: 2
    }
};

const Dialog = ({open, handleClose}) => {
    const translate = useTranslation();
    const deviceId = useInfoStore(store => store.deviceId);
    const deviceTitle = useInfoStore(store => store.deviceId);

    const setUser = useUserStore((state) => state.setUser);
    const user = useUserStore((state) => state.user);

    const data = `geomix://Pairing?deviceId=${deviceId}&title=${deviceTitle}`;

    useInterval(useCallback(async () => {
        const res = await getPairing();

        if (isOk(res) && typeof res.data == 'object') {
            console.log('user paired successfully', res);
            setUser(res.data);
            handleClose();
        }
        // handleClose might not be wrapped into useCallback, therefore do not add it as dep
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUser]), 1000);

    useEffect(() => {
        if (!isDevelopment()) {
            return;
        }

        if (!user) {
            setUser(getTestUser('10'));
        }

        handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <BaseDialog open={open} handleClose={handleClose} title={translate('app.pairing.pairing')}>
        <DialogContent>
            <Typography variant="body1"><Text>app.pairing.info</Text></Typography>
        </DialogContent>
        <DialogContent sx={styles.dialogContainer} align={'center'}>
            <Box sx={styles.qrcodeContainer}>
                <ReactQRCode value={data} level={'H'}/>
            </Box>
        </DialogContent>
        <DialogActions>
        </DialogActions>
    </BaseDialog>;
};

export default Dialog;
