import {useCallback} from 'react';

import lockerRoom from './socket';
import {useLockerRoomStore} from './zustand';

const transformUserData = (user) => {
    // do not send sensitive data like jwt
    return {
        appId: user.appId,
        id: user?.id,
        firstname: user.firstname,
        lastname: user.lastname,
        image: user.image,
    };
};

export const connectionHandler = ({selectedTeam, user, roomId, title, invited, action}) => {
    console.log('connectionhandler action:', action, 'team:', selectedTeam,'user:', user.appId, 'room:',roomId);
    console.log('lockerRoom', lockerRoom?.connected);

    if(action){
        lockerRoom.actionOnConnect = action;
    }

    if(roomId){
        lockerRoom.roomId = roomId;
    } 

    if(title){
        lockerRoom.title = title;
    }
  
    if(invited){
        lockerRoom.invitedUsers = invited;
    }

    if (user && lockerRoom && !lockerRoom?.connected){
        console.log('connect!');
        console.log('....SOCKET IO connecting with app-id auth token', user.appId);
        lockerRoom.auth.appId = user.appId;
        lockerRoom.connect();

    }
};

export const closeLockerRoomConnection = (roomId) => {
    console.log('closeLockerRoomConnection', 'room:',roomId);
    console.log('lockerRoom disconnect', lockerRoom?.connected);
    if (!lockerRoom.connected) return -1;
    lockerRoom.disconnect();
};

export const createLockerroom = (selectedTeam, user, title, roomId, invited) => {
    console.log('createLockerroomEMIT');
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('createLockerroom', selectedTeam, user, title, roomId, invited);

    lockerRoom.emit('createLockerroom', {
        title: title,
        roomId: roomId, // random roomId given
        teamId: selectedTeam,
        user: transformUserData(user),
        invited: invited,
        timeStamp: new Date().getTime(),
    });
};

export const joinLockerroom = (roomId, user) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('joinLockerroom', roomId, user);

    lockerRoom.emit('joinLockerroom', {
        roomId: roomId,
        user: transformUserData(user),
        timeStamp: new Date().getTime(),
    });
};

export const closeLockerroom = (room, user) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('closeLockerroom: ', room);

    lockerRoom.volatile.emit('closeLockerroom', {
        roomId: room.roomId,
        user: transformUserData(user),
        timeStamp: new Date().getTime(),
    });
};

export const leaveLockerroom = (room, user) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('leaveLockerroom');

    lockerRoom.volatile.emit('leaveLockerroom', {
        roomId: room.roomId,
        user: transformUserData(user),
        timeStamp: new Date().getTime(),
    });
};

export const useChangeRoomEditor = () => {
    const setLoadingChangeEditor = useLockerRoomStore((state) => state.setLoadingChangeEditor);

    const changeEditor = useCallback((room, newEditor) => {
        const roomId = room.roomId;
        if (!(lockerRoom && lockerRoom.connected)) return -1;
        // TODO FIX - why is this executed on user leaving if leaving user is not the editor
        setLoadingChangeEditor(true);
        lockerRoom.emit('requestEditorChange', roomId, transformUserData(newEditor));
    }, [setLoadingChangeEditor]);
  
    return {changeEditor};
};

export const toggleHand = (room, user) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('toggleHand');

    lockerRoom?.emit('toggleHand', {roomId: room.roomId, user: transformUserData(user)});
};

export const toggleMicrophone = (room, user) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('toggleMicrophone');

    lockerRoom?.emit('toggleMicrophone', {roomId: room.roomId, user: transformUserData(user)});
};

export const toggleMute = (room) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('toggleMute');

    lockerRoom?.emit('toggleMute', {roomId: room.roomId});
};

export const mute = (room) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('mute');

    lockerRoom?.emit('mute', {roomId: room.roomId});
};

export const unMute = (room) => {
    if (!(lockerRoom && lockerRoom.connected)) return -1;

    console.log('unMute');

    lockerRoom?.emit('unMute', {roomId: room.roomId});
};

export const pingServer = (pingCallback) => {
    const start = Date.now();
    lockerRoom?.volatile.emit('ping', () => {
        const duration = Date.now() - start;
        pingCallback(duration);
    });
};
