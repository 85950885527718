import {useEffect} from 'react';

import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {useUserStore} from 'module/user';

import {initMediaDevice} from './mediaDevice';
import {exitRoom,isRoomOpen,mediaType,pauseProducer,resumeProducer,startRoom} from './mediaLib';

const hasWindow = typeof window !== 'undefined';

async function initMedia(mediaDevices, media, setMediaDevices) {
    if (Object.entries(mediaDevices).length > 0) {
        console.log('MEDIASOUP: mediadevices already present');
        return;
    }

    console.log('INITMEDIA', media);

    // audio is required, video is optional
    let newMediaDevices = await initMediaDevice(media);
    console.log('MEDIASOUP: devices:', newMediaDevices);
    setMediaDevices(newMediaDevices);
}

const useMediaSoup = () => {
    const roomId = useLockerRoomStore((state) => state.roomId);
    const role = useLockerRoomStore((state) => state.role);
    const user = useUserStore((store) => store.user);

    const media = useLockerRoomStore((state) => state.media);
    const mediaDevices = useLockerRoomStore((state) => state.mediaDevices);
    const setMediaDevices = useLockerRoomStore((state) => state.setMediaDevices);
    // const users = useLockerRoomStore((state) => state.users);
    const active = useLockerRoomStore((state) => state.active);
    const producing = useLockerRoomStore((state) => state.producing);
    // const setProducing = useLockerRoomStore((state) => state.setProducing);
    const connected = useLockerRoomStore((state) => state.connected);
    
    // console.log('isVisible', [isVisible, mediaDevices, producing]);
    // reinitializing the media device on comeback from app background mode
    useEffect(() => {
        if (hasWindow) {
            const handleVisibilityChange = () => {
                console.log('changed state', document.visibilityState);
                // to catch the case where the app was in a phone call and lost audio device
                // TODO check if necessary
            
                initMedia(mediaDevices, media, setMediaDevices);
            };
    
            let listener = window.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                //cleanup on unmount  
                console.log('MEDIASOUP: Board exit -> Exit room1');
                exitRoom();
                window.removeEventListener(listener, handleVisibilityChange);
            };
        }

        initMedia(mediaDevices, media, setMediaDevices);

        return () => {
            //cleanup on unmount
            console.log('MEDIASOUP: Board exit -> Exit room2');
            exitRoom();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isRoomOpen()) {
            console.log('MEDIASOUP: Room already open');
        } else {
            if (roomId && (media.audio || media.video) && mediaDevices) {
                startRoom(user, role, roomId, mediaDevices);
            }
        }

    }, [active, media.audio, media.video, mediaDevices, role, roomId, user]);

    useEffect( () => {
        // console.log('microphoneStatus resume:', producing);
        if (connected) {
            if (producing) {
                resumeProducer(mediaType.audio);
            } else {
                pauseProducer(mediaType.audio);
            }
        }
            
    }, [connected , producing]);

};

export default useMediaSoup;
