import {useCallback} from 'react';

import {usePrefsStore} from 'store';

import {
    DRAWING_MODE,
    DRAWING_WIDTH,
    SELECTION_MODE,
    setLastObjectActive,
    setStrokeColor,
    setStrokeWidth
} from './canvas-helper';
import {Circle, Clipart, Line, Player, PlayerCircle, Rect, Text, Triangle} from './Drawer';
import {useCanvasStore} from './zustand';

const useMode = () => {
    const canvas = useCanvasStore(state => state.canvas);
    const tool = usePrefsStore(state => state.tool);
    const color = usePrefsStore(state => state.color);
    const prefs = usePrefsStore(state => state.prefs);

    return useCallback((editable, lastObjectActive = false) => {
        console.log('setMode', editable, color, tool, prefs);

        if (!editable) {
            canvas.isDrawingMode = false;
            canvas.selection = true;
            return ;
        }

        setStrokeColor(canvas, color?.value1 || '#000000');
        setStrokeWidth(canvas, DRAWING_WIDTH);

        let mode = null;

        if (tool === 'player') {
            if (prefs.player.type === 'playerCircle') {
                PlayerCircle({canvas, colors: color, size: prefs.player.size});
            } else {
                Player({canvas, colors: color, size: prefs.player.size, clipart: tool});
            }
        }

        if (tool === 'draw') {
            if (prefs.draw.type === 'object') {
                Clipart({canvas, color, clipart: prefs.draw.object});
            } else if (prefs.draw.type === 'shape' && prefs.draw.shape === 'pencil') {
                mode = DRAWING_MODE;
            } else {
                console.log(prefs.draw.shape);
                if (prefs.draw.shape === 'arrow') {
                    Line({canvas, stroke: color.value1, width: DRAWING_WIDTH});
                }

                if (prefs.draw.shape === 'arrowDashed') {
                    Line({canvas, stroke: color.value1, width: DRAWING_WIDTH, dashed: true});
                }

                if (prefs.draw.shape === 'rect') {
                    Rect({canvas, stroke: color.value1, width: DRAWING_WIDTH});
                }

                if (prefs.draw.shape === 'circle') {
                    Circle({canvas, stroke: color.value1, width: DRAWING_WIDTH});
                }

                if (prefs.draw.shape === 'triangle') {
                    Triangle({canvas, stroke: color.value1, width: DRAWING_WIDTH});
                }
            }
        }

        if (tool === 'text') {
            Text({canvas, stroke: color.value1});
        }

        if (tool === 'select') {
            mode = SELECTION_MODE;
            if (lastObjectActive) {
                setLastObjectActive(canvas);
            }
        }

        canvas.isDrawingMode = mode === DRAWING_MODE;
        canvas.selection = mode === SELECTION_MODE;
    }, [canvas, color, tool, prefs]);
};

export default useMode;